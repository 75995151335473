.a-btn {
  @extend %f-ui;
  display: inline-flex;
  align-items: center;
  min-width: 150px;
  border: 0;
  border-radius: 5px;
  text-align: center;
  //white-space: nowrap;
  cursor: pointer;
  transition: all 0.25s;
  -webkit-appearance: none;
  border-radius: 5px;
  padding: 16px 20px;
  text-decoration: none;
  box-shadow: 3px 3px 5px rgba(255, 255, 255, 0) inset,
    -1px -1.5px 5px rgba(0, 0, 0, 0.2) inset;
  justify-content: center;
  // min-height: 45px;
}

.a-btn--lg {
  font-size: 20px !important;
}

.a-text-link {
  &.-text-link-margin {
    margin-top: 2rem;
  }

  &::after {
    content: "";
    left: 0;
    right: 0;
    bottom: -5px;
    height: 3px;
    background: $color__background;
    position: absolute;
    border-radius: 1px;
    transition: all 300ms ease;
    max-width: 0;
  }

  @include hover-supported() {
    &::after {
      max-width: 700px;
    }
  }
}

.a-btn svg {
  margin-left: 14px;
  flex-shrink: 0;
}

.a-btn--primary {
  background: $color__primary;
  color: $color__background;

  &:hover {
    background: darken($color__primary, 10%);
  }
}

.a-btn--primary-light {
  background: $color__primary--light;
  color: $color__background;

  &:hover {
    background: darken($color__primary--light, 10%);
  }
}

.poster--btn {
  border-radius: 5px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 5;
  border: none;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;

  .icon--play {
    user-select: none;
  }
}

.a-btn--secondary,
.college-16-18 .o-section .a-btn.a-btn--secondary,
.university-centre .o-section .a-btn.a-btn--secondary,
.international-students .o-section .a-btn.a-btn--secondary,
.adult-19 .o-section .a-btn.a-btn--secondary,
.apprenticeships .o-section .a-btn.a-btn--secondary,
.employers .o-section .a-btn.a-btn--secondary {
  background: $color__secondary !important;
  color: $color__background !important;

  &:hover {
    background: darken($color__secondary, 10%) !important;
  }
}

.a-btn--tertiary {
  background: $color__tertiary !important;
  color: $color__white !important;

  &:hover {
    background: darken($color__tertiary, 10%) !important;
  }
}




.a-btn--reversed {
  background: $color__background !important;
  color: $color__secondary !important;

  &:hover {
    background: darken($color__background, 10%) !important;
  }
}


.a-btn--sm {
  min-width: 0;
  padding: 8px 6px;
  min-height: 0;

  svg {
    margin: 0;
  }
}

.a-modal--close {
  position: absolute;
  top: 1rem;
  right: 2rem;
  cursor: pointer;

  svg {
    width: 1rem;
    height: 2rem;
    color: #999;
  }
}

.a-btn--lg {
  @extend %f-input-lg;
  padding: 25px 30px;

  &.-btn-icon-sm {

    @include media-breakpoint-down(sm) {
      font-size: 0;
    }
  }
}

%a-text-link,
.a-text-link {
  @extend %f-ui;
  color: $color__secondary;
  text-decoration: none;
  position: relative;
  display: inline-block;

  svg {
    margin-left: 10px;
    transition: all 300ms ease;
  }

  &::after {
    content: "";
    left: 0;
    right: 0;
    bottom: -2px;
    height: 2px;
    background: $color__primary;
    position: absolute;
    border-radius: 1px;
    transition: all 300ms ease;
    max-width: 0;
  }

  @include hover-supported() {
    svg {
      transform: translateX(3px);
    }
  }
}

.a-text-link--primary {
  color: $color__primary;

  &::after {
    content: "";
    left: 0;
    right: 0;
    bottom: -5px;
    height: 3px;
    background: $color__primary;
    position: absolute;
    border-radius: 1px;
    transition: all 300ms ease;
    max-width: 0;
  }

  @include hover-supported() {
    &::after {
      max-width: 700px;
    }
  }
}

.a-btn--variant-1 {
  background: $color__variant--1 !important;
  color: $color__text--reversed !important;

  &:hover {
    background: darken($color__variant--1, 10%) !important;
  }
}

.a-btn--variant-2 {
  background: $color__variant--2 !important;
  color: $color__secondary !important;

  &:hover {
    background: darken($color__variant--2, 10%) !important;
  }
}

.a-btn--variant-3 {
  background: $color__variant--3 !important;
  color: $color__secondary !important;

  &:hover {
    background: darken($color__variant--3, 10%) !important;
  }
}

.a-btn--variant-4 {
  background: $color__variant--4 !important;
  color: $color__text--reversed !important;

  &:hover {
    background: darken($color__variant--4, 10%) !important;
  }
}

.a-btn--uni-1 {
  background: $color__uni--1;
  color: $color__text--reversed;

  &:hover {
    background: $color__uni--2;
  }
}

.a-btn--facebook {
  background: $color__facebook !important;
  color: $color__background !important;

  &:hover {
    background: darken($color__facebook, 10%) !important;
  }
}

.a-btn--twitter {
  background: $color__twitter !important;
  color: $color__background !important;

  &:hover {
    background: darken($color__twitter, 10%) !important;
  }
}

.a-btn--instagram {
  background: $color__instagram !important;
  color: $color__background !important;

  &:hover {
    background: darken($color__instagram, 10%) !important;
  }
}

.a-btn--youtube {
  background: $color__youtube !important;
  color: $color__background !important;

  &:hover {
    background: darken($color__youtube, 10%) !important;
  }
}

.a-btn--max {
  width: 100%;
}

.a-btn--apply {
  .a-btn--number {
    position: relative;
    left: 5px;
    background: $color__secondary;
    padding: 2.25px 5.18px;
    border-radius: 50%;
    color: $color__background;
    transition: all 0.25s;

    .a-btn:hover & {
      color: darken($color__background, 10%);
    }
  }

  // a-btn--apply__modal
  &__modal {
    max-width: 270px;
    position: absolute;
    top: calc(100% + 1rem);
    right: 0;
    z-index: 100;
    background-color: $color__background;
    color: $color__black;
    border-radius: 8px;
    margin-left: 125px;
    margin-top: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    transition: opacity 300ms ease;
    visibility: hidden;
    opacity: 0;

    &.active {
      visibility: visible;
      opacity: 1;
      display: block !important;
    }

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $color__grey;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
    }

    h4 {
      font-weight: 900;
      color: $color__secondary;
    }

    // a-btn--apply__modal-header
    &-header {
      padding: 14px 18px;
      background: $color__grey;
      border-radius: 8px 8px 0 0;
      z-index: 5;
    }

    // a-btn--apply__modal-body
    &-body {
      padding: 14px 18px;
    }

    ul {
      counter-reset: courseCounter;

      li {
        padding-left: 24px;
        padding-bottom: 16px;
        line-height: 1.25;
        color: $color__secondary;
        counter-increment: courseCounter;
        position: relative;

        &:before {
          content: counter(courseCounter) " -";
          position: absolute;
          left: 0;
          color: $color__text;
          font-weight: 600;
        }
      }
    }

    p {
      color: $color__text;
      font-size: 12px;
      font-style: italic;
      line-height: 1.5;
      margin-top: 5px;

      span {
        font-weight: 700;
      }
    }
  }
}

.nca-inner {
    padding-left:0.5rem;
}

.a-btn--added, .m-hero .a-btn.a-btn--added, .a-btn--disabled {
    background-color: $color__black--20 !important;
    color: $color__text--reversed !important;

    &:hover {
        background-color: darken($color__black--20, 10%) !important;
    }
}

.a-btn--variant-3:hover {
  background: #e6c100 !important;
}

.text-center-tablet {
  text-align: center;
  @include media-breakpoint-up(md) {
    text-align: center !important;
  }
}

.icon--caret--down {
  margin-left: 1rem;
  color: #305FC8;
}
