.m-stat {
    > h2 {
        @extend %f-h2;
    }
}

.m-stats .m-card--stat {
    text-align: center;
    padding: 30px 0;
    border-bottom: 1px solid $color__border;
    width: 100%;

    @include media-breakpoint-up(md) {
        padding: 0 40px;
        margin-top: 0;
        border: none;
    }

    h2 {
        @extend %f-h2;
        color: $color__primary;
        font-size: 3.125rem;
        line-height: 3.125rem;
        font-weight: 700;

        @include media-breakpoint-up(md) {
            font-size: 2.125rem;
        }

        @include media-breakpoint-up(lg) {
            font-size: 3.125rem;
        }
    }

    h3 {
        @extend %f-h4;
        color: $color__text;
        text-transform: none;
        margin: 10px 0 0 0;
        font-size: 0.9375rem;
        line-height: 1.2rem;
        font-weight: 700;
    }

    p {
        color: $color__text;
        margin-top: 15px;
    }
}

.m-stats {
    .col-md-4 {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .col-md-4:not(:last-child) {
        @include media-breakpoint-up(md) {
            border-right: 1px solid $color__border;
        }
    }

    .col-md-4:last-child .m-card--stat {
        border-bottom: none;
        padding-bottom: 0;
    }
}

.college-16-18 {
    .m-stats .m-card--stat h3 {
        color: $color__text;
    }
}

.university-centre {
  .m-stats .m-card--stat h3 {
    color: $color__variant--1;
  }
}

.international-students {
  .m-stats .m-card--stat h3 {
    color: $color__variant--4;
  }
}

.adult-19 {
  .m-stats .m-card--stat h3 {
    color: $color__variant--5;
  }
}

.apprenticeships {
  .m-stats .m-card--stat h3 {
    color: #ab9112;
  }
}

.employers {
  .m-stats .m-card--stat h3 {
    color: darken($color__tertiary, 10%)
  }
}

.contact--msg {
  line-height: 23px;
}