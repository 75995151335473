.g-footer {
    // margin-top: 70px;
    padding: 70px 0 50px 0;
    background: $color__background--med;
    color: $color__text;
}

.g-footer__top {
  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: left;
  }
}

.g-footer__top .g-footer__nav.g-footer__nav--primary li {
  @extend %f-label-2;
  display: block;
  line-height: 1.25em;

  + li {
    margin-top: 30px;
  }
}

.g-footer__top .g-footer__nav.g-footer__nav--secondary {
  margin-top: 40px;

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }
}

.g-footer__top .g-footer__nav.g-footer__nav--secondary li {
  @extend %f-h4;
  display: block;
  font-size: 14px;

  + li {
    margin-top: 8px;
  }
}


// Tertiary Nav (NCG)
.g-footer__top {
    h3 {
        @extend %f-h4;
        font-size: 14px;
    }
}

.g-footer__top .g-footer__nav.g-footer__nav--tertiary {

    li {
        @extend %f-body;
        margin-bottom: 0.5rem;
    }
}


// Social
.g-footer__top .g-footer__social {
    margin-top: 3rem;

    .g-footer__nav.g-footer__nav--secondary {
        margin-top: 0;
    }

    h3 {
        margin-bottom: 0.75rem;
    }

    a {
        color: $color__primary;
        font-size: 30px;

        &:hover {
            color: $color__primary--dark;
        }
    }
}

.g-footer__bottom {
    margin-top: 60px;

    .logo {
        height: 52px;
    }
}

.g-footer__bottom .g-footer__nav {
  @extend %f-ui;
  font-weight: 600;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  @include media-breakpoint-up(md) {
    justify-content: flex-start;
  }

  li {
    margin: 10px 5px auto 5px;

    @include media-breakpoint-up(md) {
      margin: 0 10px 0 0;
    }
  }

  li:not(:last-child)::after {
    content: '|';
    margin-left: 10px;
  }
}

.g-footer__credit {
    @extend %f-ui;
    text-align: center;

    @include media-breakpoint-up(md) {
        margin: 30px auto 0 auto;
        display: block;
    }

    @include media-breakpoint-up(md) {
        margin: 0;
        display: inline;
        text-align: left;
    }
}

.g-footer__contact {
  font-size: rem-calc(40);
  font-weight: 900;
  margin-top: 40px;
  display: block;

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }

  span {
    font-size: 0.550em;
    display: block;
    margin-bottom: 10px;
  }
}

.g-footer__links {
  margin-top: 40px;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-up(md) {
    align-self: flex-end;
    display: flex;
    margin-top: auto;
  }

  li {
    display: inline-flex;
    margin: 0 15px;

    @include media-breakpoint-up(md) {
      margin: 0 0 0 30px;
      display: inline-block;
    }
  }

  li a:not(.a-btn) {
    @extend %f-label-1;
  }

  li:last-child {
    display: block;
    margin-top: 20px;

    @include media-breakpoint-up(md) {
      display: inline-block;
      margin-top: 0;
    }
  }
}

.g-footer__logos {
  margin-top: 0px;
  display: flex;
  flex-flow: row wrap;
  padding: 0 10px;

  @include media-breakpoint-up(md) {
    margin-top: 60px;
    align-items: baseline;
    padding: 0;
  }

  li {
    width: 50%;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;

    @include media-breakpoint-up(md) {
      margin-right: 40px;
      width: auto;
      display: block;
      padding: 0;
    }
  }
}

.g-footer__pre {
  margin-top: 0;

  + .g-footer {
    margin-top: 0;
  }
}
