.m-filter {
  h1 {
    @include f-h1;
    font-weight: 900;

    &.h1--big {
      @include media-breakpoint-up(md) {
        font-size: 4.375rem;
      }
    }
  }
}

.m-filter__btns {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    padding-bottom: 15px;
    flex-flow: row wrap;

    &.m-filter__btns-margin {
      margin-top: 20px;
    }

    @include media-breakpoint-down(sm) {
      flex-flow: row nowrap !important;
      overflow-x: scroll;
		}
}

.m-filter-content {
  .m-filter-content-text {
    padding: 0 20px 20px 20px;
  }
}

.m-filter__btns {
    display: flex;
    justify-content: flex-start;
    margin-top: 5px;
    padding-bottom: 15px;
    flex-flow: row wrap;

    // m-filter__btns--subject-areas
    &--subject-areas {
      justify-content: flex-start;

      @media (min-width: 1100px) {
        flex-wrap: nowrap;
      }
    }
    
    /* On iOS, override default scrollbar baheviour - https://stackoverflow.com/questions/1202425/making-the-main-scrollbar-always-visible */
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
      background-color: rgba(0, 0, 0, .1);
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, .5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .2);
    }
}

.m-filter__btn {
  margin: 10px 5px 15px 5px;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  min-width: 150px;
  border: 0;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.25s;
  -webkit-appearance: none;
  padding: 25px;
  text-decoration: none;
  justify-content: center;
  background-color: $color__black--1;
  color: $color__black--36;

  &.m-filter__btn--primary {
    &.active {
      background: $color__primary;
      color: $color__background;

      &:after {
        content: "";
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid $color__primary;
        top: 100%;
        position: absolute;
      }

      > svg {
        opacity: 0.5;
      }

      &:hover {
        background-color: $color__primary;
      }
    }

    &:hover {
      background: darken($color__primary, 10%);
    }
  }

  &.m-filter__btn--secondary {
    &.active {
      background: $color__secondary;
      color: $color__background;

      &:after {
        content: "";
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid $color__secondary;
        top: 100%;
        position: absolute;
      }

      > svg {
        opacity: 0.5;
      }

      &:hover {
        background-color: $color__secondary;
        color: $color__background;
      }
    }

    &:hover {
      background: darken($color__secondary, 10%);
      color: $color__background;
    }
  }

  &.m-filter__btn--tertiary {
    &.active {
      background: $color__tertiary;
      color: $color__secondary;

      &:after {
        content: "";
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid $color__tertiary;
        top: 100%;
        position: absolute;
      }

      > svg {
        opacity: 0.5;
      }

      &:hover {
        background-color: $color__tertiary;
        color: $color__background;
      }
    }

    &:hover {
      background: darken($color__tertiary, 10%);
      color: $color__background;
    }
  }

  &.m-filter__btn--variant-1 {
    &.active {
      background: $color__variant--1;
      color: $color__text--reversed;

      &:after {
        content: "";
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid $color__variant--1;
        top: 100%;
        position: absolute;
      }

      > svg {
        opacity: 0.5;
      }

      &:hover {
        background-color: $color__variant--1;
        color: $color__background;
      }
    }

    &:hover {
      background: darken($color__variant--1, 10%);
      color: $color__background;
    }
  }

  &.m-filter__btn--variant-2 {
    &.active {
      background: $color__variant--2;
      color: $color__secondary;

      &:after {
        content: "";
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid $color__variant--2;
        top: 100%;
        position: absolute;
      }

      > svg {
        opacity: 0.5;
      }

      &:hover {
        background-color: $color__variant--2;
        color: $color__background;
      }
    }

    &:hover {
      background: darken($color__variant--2, 10%);
      color: $color__background;
    }
  }

  &.m-filter__btn--variant-3 {
    &.active {
      background: $color__variant--3;
      color: $color__secondary;

      &:after {
        content: "";
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid $color__variant--3;
        top: 100%;
        position: absolute;
      }

      > svg {
        opacity: 0.5;
      }

      &:hover {
        background-color: $color__variant--3;
        color: $color__background;
      }
    }

    &:hover {
      background: darken($color__variant--3, 10%);
      color: $color__background;
    }
  }

  &.m-filter__btn--variant-4 {
    &.active {
      background: $color__variant--4;
      color: $color__text--reversed;

      &:after {
        content: "";
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid $color__variant--4;
        top: 100%;
        position: absolute;
      }

      > svg {
        opacity: 0.5;
      }

      &:hover {
        background-color: $color__variant--4;
        color: $color__background;
      }
    }

    &:hover {
      background: darken($color__variant--4, 10%);
      color: $color__background;
    }
  }

  &:hover {
    background-color: $color__secondary;
	color: $color__white;
  }

  &.active {
    background-color: $color__primary;
    color: $color__white;
    position: relative;

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid $color__primary;
      top: 100%;
      position: absolute;
      left: 42%;
    }

    > svg {
      opacity: 0.5;
    }

    &:hover {
      background-color: $color__primary;
    }
  }

  &.active-dark {
    background-color: $color__secondary;
    color: $color__white;
    position: relative;

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid $color__secondary;
      top: 100%;
      position: absolute;
    }

    > svg {
      opacity: 0.5;
    }

    &:hover {
      background-color: $color__secondary;
    }
  }

  > svg {
    margin-left: 10px;
  }
}

.m-filter-search {
  position: relative;
  margin-bottom: 30px;
}

.m-filter-search .icon--search {
  color: $color__secondary;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 26px;
  height: 26px;

  @include media-breakpoint-up(md) {
    left: 20px;
    width: 20px;
    height: 20px;
  }
}

.m-filter-search__input {
  @extend %f-input-lg;
  background: $color__background;
  padding: 12px 55px;
  border-radius: 6px;
  border: none;
  width: 100%;
  font-size: 1rem;
  border: 2px solid $color__black--10;
  transition: all .3s ease-in-out;
  line-height:normal;

  @include placeholder() {
      color: $color__placeholder;
  }

  &:focus, &:active {
    border: 2px solid $color__primary;
  }
}

.m-filter__block {
  margin-bottom: 40px;

  h4 {
    margin-bottom: 2px;
    color: $color__secondary;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 800;
  }

  a {
    span {
      font-size: 14px;
      font-weight: 600;
      position: relative;
      display: inline-block;

      &:after {
        content: "";
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        background: $color__text;
        position: absolute;
        z-index: 1;
        border-radius: 1px;
      }
    }
  }

  // m-filter__block--checkbo
  &--checkbox {
    margin-bottom: 40px;
    padding-bottom: 20px;
    border-bottom: 2px solid $color__black--10;

    h4 {
      margin-bottom: 20px;
      color: $color__secondary;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 800;
    }

    .m-form__checkbox {
      label {
        font-size: 14px;
      }

      input[type=checkbox]:checked {
        + label {
          color: $color__secondary;
        }
      }
    }
  }

  // m-filter__block--radio
  &--radio {
    margin-bottom: 40px;
    padding-bottom: 20px;
    border-bottom: 2px solid $color__black--10;

    h4 {
      margin-bottom: 20px;
      color: $color__secondary;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 800;
    }

    .m-form__radio {
      label {
        font-size: 14px;
      }

      input[type=radio]:checked {
        + label {
          color: $color__secondary;
        }
      }
    }
  }
}

.m-filter__select {
  position: relative;

  .form-control {
    padding: 20px 0 10px;
    border-bottom: 2px solid $color__black--10;
  }

  &::after {
    content: '';
    background-image: url(/dist/images/chevron--down.svg);
    background-repeat: no-repeat;
    background-position: center center;
    width: 30px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }

  &.active {
    &::after {
      content: '';
      background-image: url(/dist/images/chevron--up.svg);
    }
  }
}

.m-filter__pagination {
  margin-top: 30px;

  button {
    border: none;
    background: none;
    cursor: pointer;
    color: $color__black--60;
    font-weight: 600;

    &.active {
      position: relative;
      display: inline-block;

      &:after {
        content: "";
        left: 50%;
        transform: translateX(-50%);
        bottom: -1px;
        height: 1px;
        background: $color__black--60;
        position: absolute;
        z-index: 1;
        border-radius: 1px;
        width: 10px;
      }
    }

    &:hover {
      svg.icon--arrow--left {
        transform: translateX(-3px);
      }

      svg.icon--arrow {
        transform: translateX(3px);
      }
    }
  }

  svg {
    color: $color__secondary;
    transition: all 300ms ease;
  }
}

.m-filter__toggles {
    margn-top: 20px;
    @extend .f-body;
}



@-webkit-keyframes 'loadbars' {
	0% {
		opacity: 1;
	}

	13% {
		opacity: 0.8;
	}

	26% {
		opacity: 0.6;
	}

	39% {
		opacity: 0.4;
	}

	52% {
		opacity: 0.2;
	}

	65% {
		opacity: 0.4;
	}

	78% {
		opacity: 0.6;
	}

	91% {
		opacity: 0.8;
	}

	100% {
		opacity: 1;
	}
}

@-webkit-keyframes 'loaddots' {
	0% {
		opacity: 1;
	}

	33% {
		opacity: 0.5;
	}

	66% {
		opacity: 0.5;
	}

	100% {
		opacity: 1;
	}
}

.m-filter__loading {
  position: relative;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;

    h4 {
      color: $color__primary;
      text-transform: uppercase;
      font-weight: 800;

      span {
        &:nth-child(1) {
          -webkit-animation-name: loaddots;
          -webkit-animation-duration: 1.2s;
          -webkit-animation-timing-function: ease-in-out;
          -webkit-animation-iteration-count: infinite;
          -webkit-animation-delay: 0s;
          animation-name: loaddots;
          animation-duration: 1.2s;
          animation-timing-function: ease-in-out;
          animation-iteration-count: infinite;
          animation-delay: 0s;
        }

        &:nth-child(2) {
          -webkit-animation-name: loaddots;
          -webkit-animation-duration: 1.2s;
          -webkit-animation-timing-function: ease-in-out;
          -webkit-animation-iteration-count: infinite;
          -webkit-animation-delay: -0.8s;
          animation-name: loaddots;
          animation-duration: 1.2s;
          animation-timing-function: ease-in-out;
          animation-iteration-count: infinite;
          animation-delay: -0.8s;
        }

        &:nth-child(3) {
          -webkit-animation-name: loaddots;
          -webkit-animation-duration: 1.2s;
          -webkit-animation-timing-function: ease-in-out;
          -webkit-animation-iteration-count: infinite;
          -webkit-animation-delay: -1.6s;
          animation-name: loaddots;
          animation-duration: 1.2s;
          animation-timing-function: ease-in-out;
          animation-iteration-count: infinite;
          animation-delay: -1.6s;
        }

      }
    }
  }

  svg {
    width: 160px;
    height: auto;
    color: $color__primary;
    margin-bottom: 60px;

    polygon {
      &:nth-child(2) {
        -webkit-animation-name: loadbars;
        -webkit-animation-duration: 1.2s;
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-delay: 0s;
        animation-name: loadbars;
        animation-duration: 1.2s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-delay: 0s;
      }

      &:nth-child(3) {
        -webkit-animation-name: loadbars;
        -webkit-animation-duration: 1.2s;
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-delay: -0.8s;
        animation-name: loadbars;
        animation-duration: 1.2s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-delay: -0.8s;
      }

      &:nth-child(4) {
        -webkit-animation-name: loadbars;
        -webkit-animation-duration: 1.2s;
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-delay: -1.6s;
        animation-name: loadbars;
        animation-duration: 1.2s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-delay: -1.6s;
      }

      &:nth-child(5) {
        -webkit-animation-name: loadbars;
        -webkit-animation-duration: 1.2s;
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-delay: -2.4s;
        animation-name: loadbars;
        animation-duration: 1.2s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-delay: -2.4s;
      }

      &:nth-child(6) {
        -webkit-animation-name: loadbars;
        -webkit-animation-duration: 1.2s;
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-delay: -3.2s;
        animation-name: loadbars;
        animation-duration: 1.2s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-delay: -3.2s;
      }
    }
  }
}

.m-filter__dropdown {
  width: 100%;

  select {
    border: 2px solid $color__black--10 !important;

    &:active, &:focus {
      border: 2px solid $color__primary !important;
    }
  }
}
