.m-banner {
    background: $color__tertiary;
    color: $color__text--reversed;
    display: flex;
    align-items: center;
    padding: 25px 0;

    @include media-breakpoint-up(md) {
        padding: 50px 0;
    }

    &.m-banner--variant-3 {
        background: $color__variant--3;
    }
}

.m-banner h2 {
  @extend %f-h2;
}

.m-banner p:not(:first-child) {
  margin-top: 10px;
}

.m-banner [class^='col']:not(:first-child) {
  margin-top: 10px;

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }
}

.strip--two--mobile {
    background-position: right!important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  @include media-breakpoint-up(md) {
     background-position: top center !important;
  }
}