.m-tabs__nav--vertical {
  margin-top: 15px;

  .m-tabs__nav-link {
    width: 100%;
    padding: 20px;
  }

  .m-tabs__nav-link:last-child {
    border-bottom: 1px solid rgba($color__text, 0.5);
  }

  .m-tabs__nav-link:not(:first-child) {
    border-top: 1px solid rgba($color__text, 0.5);
  }

  .m-tabs__nav-link.active {
    background: $color__caption;
    border-radius: $border-radius;
    border-color: transparent;

    + .m-tabs__nav-link {
      border-color: transparent;
    }

    + .m-tabs__nav-link:last-child {
      border-bottom-color: rgba($color__text, 0.5);
    }
  }

}

.m-tabs__nav-link {
  @extend %f-h4;
  color: $color__secondary;
  display: flex;

  svg {
    opacity: 0.6;
    margin-left: auto;
    flex-shrink: 0;
    margin-top: 0.25em;
  }

  &.active svg {
    opacity: 1;
  }
}

.m-tabs__panel {
    background: $color__caption;
    padding: 20px;
    border-radius: $border-radius;

    @include media-breakpoint-up(md) {
        padding: 35px;
    }

    &.fade {
        //transition: max-height 0.45s, opacity 0.25s;
        //overflow: hidden;
    }


    &.fade:not(.show) {
        max-height: 0px;
    }

    &.fade.show {
        max-height: none;
    }
}

.m-tabs__content {
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "tab-content";
}

.m-tabs__panel {
  grid-area: tab-content;
  align-self: flex-start;
}

.m-tabs__nav-link {
  svg {
    transition: all 300ms ease;
  }

  &:hover {
    svg {
      transform: translateX(3px);
    }
  }
}

.subject-area--tabs {
  .fade {
    display: none;
    opacity: 1;
    visibility: visible;
    &.active {
      display: block;
    }
  }
}
