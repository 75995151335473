.m-social__btns {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  padding-bottom: 15px;
  flex-flow: row wrap;

  .a-btn {
    margin: 10px 5px 0 5px;
    flex-shrink: 0;
    width: auto;
    min-width: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.m-social__btns--span {
    display: none;

    @include media-breakpoint-up(sm) {
        display: block;
    }
}


.m-card__handle {
    a {
        word-break: break-all;
    }
}

