.o-section--background {
	background: $color__background--med;
	padding-top: 60px;
	padding-bottom: 50px;
	margin-top: 0;
}

.o-section {
	margin-top: 30px;

	@include media-breakpoint-up(md) {
		margin-top: 30px;
	}

	+ .o-section--background & {
		margin-top: 0;
	}

  &.o-section-margin-none {
    margin: 0;
  }

  &.o-section--large {
    margin-top: 50px;

    @include media-breakpoint-up(md) {
      margin-top: 100px;
    }
  }

  &.-section-padding {
	  padding: 3rem 0;
  }

  &.bg-blue {
	background: $color__secondary;


  }

  &.text-shadow {
	text-shadow: 1px 2px 2px rgba(0,0,0,0.5);  
  }
}

.applicationform {
	.o-section--background {
	    padding-top: 0px;
	}
}

.o-section__header {

	h1,
	h2,
	h3,
	h4 {
		color: $color__secondary;

		+ p {
			margin-top: 15px;
		}

		+ .a-btn {
			margin-top: 25px;
		}
	}

	h2 {
		@extend %f-h2;
	}

	h2 span {
		color: $color__primary;
	}

	h3 {
		@extend %f-h3;
	}

	h4 {
		@extend %f-h4;
		color: $color__primary;

		+ h2,
		+ h3 {
			margin-top: 5px;
		}
	}

	+ .o-section__content {
		margin-top: 15px;
	}

	.a-text-link + .a-text-link {
		margin-left: 25px;
	}

	&.-header-quote {
		padding: 25px 0;
		@include media-breakpoint-up(sm) {
			padding: 0;
		}
	}
}

.o-section--background {

	h2,
	h3 {
		color: $color__secondary;
	}
}

.m-hero--imageAndButton{
	h1, p{ color: $color__secondary; }
}

.o-section__content p {
  + .m-expand {
    margin-top: 20px;
  }

  a {
    position: relative;
    //display: inline-block;

    /*text-decoration: underline;
    text-underline-offset: 3px;*/

    /*
    &:after {
      content: "";
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background: $color__text;
      position: absolute;
      z-index: 1;
      border-radius: 1px;
    }
    */
  }
}

.with-list-style ul, .o-section__content ul, .grid-container ul {
	margin-top: 1em;
}

.with-list-style, .o-section__content {
	ul li {
		list-style: disc;
	}
	ol li {
		list-style: decimal;
	}
	ol ol li {
		list-style: lower-alpha;
	}
	ul ul li {
		list-style: circle;
	}
	li + li {
		margin-top: .25em;
	}
	li {
		margin-left: 2.25em;
	}
}

.o-section__content h2 {
	@extend %f-h2;

	+ p {
		margin-top: 20px;
	}
}

.o-section__content h3 {
	@extend %f-h3;
	color: $color__secondary;

	+ p {
		margin-top: 15px;
	}
}

.o-section__content .a-media {
	border-radius: $border-radius;
	overflow: hidden;
	margin-bottom: 30px;

	@include media-breakpoint-up(md) {
		margin-bottom: 0px;
	}

	img {
		width: 100%;
		display: block;
	}
}

.o-section__content .m-card {
	margin-top: 30px;

	@include media-breakpoint-up(lg) {
		margin-top: 15px;
	}
}

.o-section__content .m-carousel {
  margin-top: 15px;

  @include media-breakpoint-up(md) {
    margin-top: 40px;
  }
}

.o-section__box {
	padding: 40px 30px;
	position: relative;
	border-radius: $border-radius;
	background: $color__background--dark;

	@include media-breakpoint-up(xl) {
		padding: 40px 0;

		&::before {
			content: '';
			position: absolute;
			border-radius: $border-radius;
			background: $color__background--dark;
			top: 0;
			right: -45px;
			bottom: 0;
			left: -45px;
		}
	}
}

.o-section--overlap {
	background: $color__background--med;

	@include media-breakpoint-up(md) {
		background: none;
	}

	.o-section__content {
		position: relative;
		padding-bottom: 50px;

		&::before {
			content: '';
			position: absolute;
			width: 100vw;
			top: 0px;
			bottom: 0;
			left: 50%;
			margin-left: -50vw;
			background: $color__background--med;

			@include media-breakpoint-up(md) {
				top: 45px;
			}
		}

		&.-section-quote {
			padding-bottom: 0;
		}
	}
}

.o-section--overlap-dark {
	background: $color__background;

	.o-section__content {
		position: relative;
		padding-bottom: 60px;
		padding-top: 60px;

		@include media-breakpoint-up(md) {
			padding-top: 0;
			padding-bottom: 75px;
		}

		&::before {
			content: '';
			position: absolute;
			width: 100vw;
			top: 0;
			bottom: 0;
			left: 50%;
			margin-left: -50vw;
			background: $color__secondary;

			@include media-breakpoint-up(md) {
				top: 130px;
			}
		}
	}
}

.o-section--overlap-top {
	background: $color__background;

	.o-section__content {
		position: relative;
		padding-bottom: 60px;
		padding-top: 60px;

		@include media-breakpoint-up(md) {
			padding-top: 60px;
			padding-bottom: 0px;
		}

		&::before {
			content: '';
			position: absolute;
			width: 100vw;
			top: 0px;
			bottom: 0;
			left: 50%;
			margin-left: -50vw;
			background: $color__primary;

			@include media-breakpoint-up(md) {
				bottom: 150px;
			}
		}
	}
}

.gradient-container {
	background: #ffffff;
	/* Old browsers */
	background: -moz-linear-gradient(top, #ffffff 50%, #ececec 50%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(top, #ffffff 50%, #ececec 50%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #ffffff 50%, #ececec 50%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ececec', GradientType=0);
	/* IE6-9 */
}

.row-reverse {
	flex-direction: row-reverse;
}

.o-section--background-top {
	background: $color__background;
	position: relative;
	padding-bottom: 0;

	&::before {
		content: '';
		position: absolute;
		width: 100vw;
		top: 0px;
		bottom: 0px;
		left: 50%;
		margin-left: -50vw;
		background: $color__background--med;

		@include media-breakpoint-up(md) {
			bottom: 170px;
		}
	}
}

.o-section__btns {
	margin: 0 auto;
	display: flex;
    flex-wrap: wrap;

	@include media-breakpoint-up(md) {
		display: flex;
		justify-content: center;
	}

	.a-btn {
		width: 100%;
		margin-top: 5px;
		justify-content: center;

		@include media-breakpoint-up(md) {
			width: auto;
			margin: 10px 5px;
		}
	}
}

.o-section-lower + .g-footer {
	margin-top: 0;
}

.o-section-lower {
	margin-bottom: 0 !important;
}


.o-section--inverse {
    color: $color__text--reversed;
    background: url(/dist/images/bg-blue-1.png) $color__primary no-repeat top center;
    background-size: cover;
    padding: 55px 0;

    .o-section__header {

        h2,
        h3 {
            @extend %f-h2;
        }

        h1,
        h2,
        h3,
        h4,
        p {
            color: $color__text--reversed;
        }
    }

    .o-section__content {
        margin-top: 40px;
    }

    .o-section__icon-text {
        @extend %f-ui;
        position: relative;
        margin: 20px 0;
        text-align: center;

        @include media-breakpoint-up(md) {
            margin-top: 0;
            text-align: left;
            margin: 0 0 0 20px;
        }
    }

    .o-section__icon-text:first-child {
        margin: 20px 0;

        @include media-breakpoint-up(md) {
            margin: 0 0 0 20px;
        }
    }

    .col-md-4 .o-section__icon-text {
        @include media-breakpoint-up(md) {
            padding-left: 65px;
            margin-left: 0;
        }
    }

    .o-section__icon-text svg {
        transform: rotate(-5deg);
        display: block;
        margin: 0 auto 20px auto;

        @include media-breakpoint-up(md) {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%) rotate(-5deg);
            margin: 0;
        }
    }

    .o-section__icon-text span {
        @extend %f-h3;
    }

    .o-section__icon-text svg + h5 + span {
        display: block;
        margin-top: 10px;
    }

    .o-section__info-text {
        text-align: center;

        h4 {
            @extend %f-label-2;
        }

        p {
            margin-top: 10px;
        }
    }

    .o-section__btns {
        margin-top: 30px;
        text-align: center;

        @include media-breakpoint-up(md) {
            margin-top: 20px;
            text-align: left;
        }

        @include media-breakpoint-up(xl) {
            margin-top: 0px;
        }
    }

    .o-section__btns .a-btn {
        margin-top: 10px;

        @include media-breakpoint-up(md) {
            margin-top: 0px;
        }
    }

    .o-section__icon-text + .o-section__btns {
        @include media-breakpoint-up(md) {
            margin-left: 30px;
        }
    }

    &.o-section-getintouchstripone {
        .o-section__icon-text {
            margin-bottom: 10px;
            margin-left: 0;

            @include media-breakpoint-up(xl) {
                margin-bottom: 0;
                margin-left: 20px;
            }
        }

        .o-section__btns {
            margin-left: 0;

            @include media-breakpoint-up(xl) {
                margin-left: 30px;
            }
        }
    }
}

.o-section--background.o-section--inverse {
	background: $color__secondary;

	.o-section__header {
		h3 {
			@extend %f-h3;
		}
	}

	.o-section__btns {
		margin: 0;
	}
}

.o-section--grid {
	width: 100%;
	max-width: 1638px;
	margin: 30px auto 0 auto;

	@include media-breakpoint-up(md) {
		margin-top: 60px;
	}
}

.o-section--grid .o-section__header {
	h2 span {
		color: $color__primary;
		display: block;
	}

	+ .o-section__content {
		margin-top: 30px;

		@include media-breakpoint-up(md) {
			margin-top: 40px;
		}
	}

	p {
		margin-top: 20px;

		@include media-breakpoint-up(md) {
			margin-top: 0px;
		}
	}
}

	.o-section--related-links .o-section__header + .o-section__content {
		margin-top: 15px;
	}

// Related Links NCG

.o-section--related-links {
	position: relative;
	margin-bottom: 0;
	padding-top: 60px;
	padding-bottom: 60px;
	//background: #f0f0f0;

	/*
	@include media-breakpoint-up(md) {
		padding-top: 30px;
		background: linear-gradient(0deg, rgba(240,240,240,1) 0%, rgba(240,240,240,1) 55%, rgba(255,255,255,0.49343487394957986) 75%);
	}

	@include media-breakpoint-up(xl) {
		background: #f0f0f0;
	}
	*/

	/*
	&:before {
		@include media-breakpoint-up(md) {
			content: '';
			z-index: 1;
			position: absolute;
			bottom: 0;
			right: 0;
			width: 100%;
			height: 70%;
			max-height: 400px;
			background: url('/dist/images/west-lancs-bg-2.png') bottom right no-repeat;
			background-size: contain;
		}

		@include media-breakpoint-up(xl) {
			bottom: auto;
			top: 0;
			background: url('/dist/images/west-lancs-bg-2.png') bottom right no-repeat #fff;
			background-size: contain;
		}
	}
	*/

	.o-section--grid {
		position: relative;
		z-index: 2;
	}

	.o-section__content {
	}

	.m-card__content {
		text-transform: none;

		h3 {
			text-transform: none !important;
			font-size: 16px;

		}

		h4 {
			font-size: 20px;
			line-height: 1.4em;
		}
	}
}

.o-section:last-child {
	margin-bottom: 0 !important;
}


.o-section--grid .o-section__content {
	@include clearfix;

	@include media-breakpoint-up(md) {
		display: block;

		.o-section__lockup {
			float: left;
			width: 60%;
		}

		.o-section__cards {
			float: right;
			width: calc(40% - 20px);

			.m-card__img img {
				min-height: 250px;
			}
		}

		.o-section__links {
			float: left;
			width: 60%;
			margin-top: 50px;
			justify-content: flex-end;
		}
	}

	@include media-breakpoint-up(lg) {
		.o-section__cards {
			.m-card__img img {
				min-height: 300px;
			}
		}
	}

	.o-section__links {
		@include media-breakpoint-up(xl) {
			padding-right: 60px;
		}
	}

	.o-section__links li:not(:first-child) {
		@include media-breakpoint-up(md) {
			border-left: 1px solid $color__border;
		}
	}

	.o-section__links li:last-child {
		border-bottom: 1px solid $color__border;

		@include media-breakpoint-up(md) {
			border-bottom: none;
		}
	}

	.o-section__links li {
		padding: 25px 0px;
		border-top: 1px solid $color__border;
		display: flex;
		width: 100%;

		@include media-breakpoint-up(md) {
			padding: 20px 25px;
			border: none;
			width: calc(33.33%);
		}

		@include media-breakpoint-up(lg) {
			//width: auto;
			padding: 20px 30px;
		}

		@include media-breakpoint-up(xl) {
			padding: 35px 40px;
		}
	}

	.o-section__links .o-section__links-item {
		@extend %f-h4;
		display: flex;
		text-align: center;
		color: $color__secondary;
		flex-flow: row wrap;
		width: 100%;
		align-items: center;

		@include media-breakpoint-up(md) {
			display: block;
			width: auto;
			margin: 0 auto;
		}
	}



	.o-section__links .o-section__links-item .a-btn {
		flex-shrink: 0;
		align-self: flex-end;
		margin: 0 0 0 auto;

		@include media-breakpoint-up(md) {
			margin: 25px auto 0 auto;
			display: table;
			background: $color__placeholder;

			&:hover {
				background: darken($color__placeholder, 10%);
			}
		}
	}

	.m-card--overlay:first-child {
		margin-top: 0;
	}

	.m-card--overlay + .m-card--overlay {
		margin-top: 20px;
	}

	.m-card--overlay .m-card__img {
		margin-bottom: 0;
		border-radius: 0;
	}
}

.o-section--grid .o-section__lockup {
	position: relative;

	img {
		@include media-breakpoint-down(md) {
			object-fit: cover;
			min-height: 350px;
		}

		@include media-breakpoint-down(sm) {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
			min-height: none;
		}
	}

	.o-section__caption {
		color: $color__text--reversed;
		height: auto;
		min-height: 280px;
		padding: 10% 20px;
		display: flex;
		flex-flow: row wrap;
		position: relative;

		@include media-breakpoint-up(md) {
			position: absolute;
			left: 30px;
			bottom: 30px;
			min-height: 0;
			padding: 0;
			display: block;
		}

		@include media-breakpoint-up(lg) {
			left: 95px;
			bottom: 80px;
		}
	}

	.o-section__caption h2 {
		@extend %f-h1;
		text-shadow: -2.516px -1.634px 23px rgba(0, 0, 0, 0.6);

		@include media-breakpoint-up(md) {
			font-size: 2.425rem;
			line-height: 2.425rem;
		}

		@include media-breakpoint-up(lg) {
			//max-width: 336px;
		}

		@include media-breakpoint-up(xl) {
			font-size: 3.125rem;
			line-height: 3.125rem;
		}
	}

	.o-section__caption p {
		text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.8);

		@include media-breakpoint-up(md) {
			max-width: 500px;
		}
	}

	.o-section__caption .a-btn {
		margin-top: 40px;
		align-self: flex-end;
	}
}

.o-section__links {
	display: flex;
	align-items: flex-start;
	flex-flow: row wrap;
	padding: 0 20px;
	margin-top: 30px;

	@include media-breakpoint-up(md) {
		border: none;
		padding: 0;
		margin-top: 0;
	}

	li + li {
		margin-top: 0 !important;
	}

	li {
		width: 100%;
		margin-left: 0;

		@include media-breakpoint-up(md) {
			padding: 0px 15px;
			width: 25%;
			border: none;
			// margin-left: 25px;
		}
	}

	li img {
		display: block;
		margin: 0 auto 15px auto;
	}

	.adult-19
	.nav-pods--42 &{
		.o-section__links-item .a-btn {
		    margin: 25px auto 0 auto;
		    background: $color__placeholder;
			display: table;
			color: $color__background;

			&:hover {
					color: #1a2d59;
					background-color: $color__teal;
			}
		}
	}

	.apprenticeships
	.nav-pods--42 & {
		.o-section__links-item .a-btn {
				background: $color__placeholder;
				display: table;
				color: $color__background !important;

				&:hover {
						background-color: #AC8E15;
				}
			}
		}

		.university-centre
		.nav-pods--42 & {
		    .o-section__links-item .a-btn {
		        background: $color__placeholder;
		        display: table;
		        color: $color__background !important;

		        &:hover {
		            background-color: #E12F38;
		        }
		    }
		}

	.o-section__links-item .a-btn {
		margin: 25px auto 0 auto;
		background: $color__placeholder;
		display: table;
	}

	.o-section__links-item {
		@extend %f-h4;
		text-align: center;
		color: $color__secondary;
		width: 100%;
		display: block;

		@include media-breakpoint-up(md) {
			display: block;
			width: auto;
			margin: 0 auto;
		}
	}
}

.o-section__card {
	.o-section__header + .o-section__content {
		margin-top: 15px;
	}
}

.o-section--primary {
	background: $color__primary;
	color: $color__text--reversed;
	padding: 45px 0 35px 0;

	h1,
	h2,
	h3,
	h4,
	p {
		color: $color__text--reversed;
	}

	.m-card {
		color: $color__text--reversed;

		h1,
		h2,
		h3,
		h4,
		p,
		svg {
			color: $color__text--reversed;
		}
	}

	.m-card--image .m-card__content > svg {
    color: $color__text--reversed !important;
	}
}

.o-section--variant-1 {
	background: $color__variant--1;
	color: $color__text--reversed;
	padding: 35px 0 35px 0;

	h1,
	h2,
	h3,
	h4,
	p {
		color: $color__text--reversed;
	}

	.m-card {
		color: $color__text--reversed;

		h1,
		h2,
		h3,
		h4,
		p,
		svg {
			color: $color__text--reversed;
		}
	}

	.m-card--image .m-card__content > svg {
    color: $color__text--reversed !important;
	}
}

.o-section--variant-2 {
	background: $color__variant--2;
	color: $color__secondary;
	padding: 45px 0 35px 0;

	h1,
	h2,
	h3,
	h4,
	p {
		color: $color__secondary;
	}

	.m-card {
		color: $color__secondary;

		h1,
		h2,
		h3,
		h4,
		p,
		svg {
			color: $color__secondary;
		}
	}

	.m-card--image .m-card__content > svg {
    color: $color__secondary !important;
	}
}

.o-section--variant-3 {
	background: $color__variant--3;
	color: $color__secondary;
	padding: 45px 0 35px 0;

	h1,
	h2,
	h3,
	h4,
	p {
		color: $color__secondary;
	}

	.m-card {
		color: $color__secondary;

		h1,
		h2,
		h3,
		h4,
		p,
		svg {
			color: $color__secondary;
		}
	}

	.m-card--image .m-card__content > svg {
    color: $color__secondary !important;
	}
}

.o-section--variant-4 {
	background: $color__variant--4;
	color: $color__text--reversed;
	padding: 45px 0 35px 0;

	h1,
	h2,
	h3,
	h4,
	p {
		color: $color__text--reversed;
	}

	.m-card {
		color: $color__text--reversed;

		h1,
		h2,
		h3,
		h4,
		p,
		svg {
			color: $color__text--reversed;
		}
	}

	.m-card--image .m-card__content > svg {
    color: $color__text--reversed !important;
	}
}

.o-section--tertiary {
	background: $color__tertiary;
	color: $color__secondary;
	padding: 45px 0 35px 0;

	h1,
	h2,
	h3,
	h4,
	p {
		color: $color__secondary;
	}

	.m-card {
		color: $color__secondary;

		h1,
		h2,
		h3,
		h4,
		p,
		svg {
			color: $color__secondary;
		}
	}

	.m-card--image .m-card__content > svg {
    color: $color__secondary !important;
	}
}

.o-section--event {

	h2 {
		@extend %f-h3;
		color: $color__primary;
	}

	h3 {
		@extend %f-label-2;
		color: $color__secondary;
	}

	h3 + p {
		margin-top: 25px;
	}

	.o-section__img {
		overflow: hidden;
		width: 100%;
		border-radius: $border-radius;
		margin-top: -70px;

		+ h3 {
			margin-top: 30px;
		}
	}

	.o-section__img img {
		width: 100%;
		display: block;
	}

	.m-hero--event + & {
		padding: 40px 0 0 0;
		margin-top: 0;
	}

	h3 + .m-tags {
		margin-top: 10px;
	}

	.m-tags li {
		display: inline-block;
		margin: 10px 5px 0 0;
	}

	.m-tags li a {
		display: block;
		background: $color__background--med;
		border-radius: 28px;
		padding: 6px 15px;
	}

	.m-share {
		margin-top: 30px;
		display: flex;
		align-items: center;
	}

  .m-author {
    display: flex;
    align-items: center;

    p {
      margin-top: 0;
      margin-left: 10px;
    }
  }

  .m-share__item {
    color: $color__placeholder;
    margin-left: 10px;

		svg {
			width: 30px;
			height: 30px;
		}
	}

  .m-map__wrapper {
    margin-top: 30px;

    @include media-breakpoint-up(md) {
      margin-top: 80px;
    }
  }

	.m-map {
		padding-bottom: 56%;
	}
}

.o-section--details {
  margin-top: 30px;
  .m-breadcrumb {
    margin-bottom: 0px;
  }
}

.o-section--blue_curve {
	background: url(/dist/images/bg-blue-2.png) $color__primary no-repeat top center;
	background-size: cover;
  padding: 35px 0;

  @include media-breakpoint-up(md) {
    padding: 35px 0 55px 0;
  }
}

.o-section--short {
  margin-top: 30px;

  @include media-breakpoint-up(md) {
    margin-top: 30px;
  }
}

.o-section--background-semi {
  background: repeating-linear-gradient(#fff, #fff 49.9%, #ececec 50.1%, #ececec 100%);
  margin-top: 0;
}

.o-section--news {

  margin-top: 30px;

  .m-breadcrumb {
    margin-bottom: 40px;

    @include media-breakpoint-up(md) {
      margin-bottom: 60px;
    }
  }

  h2 {
    @extend %f-h3;
    color: $color__primary;
  }

  h3 {
    @extend %f-label-2;
    color: $color__secondary;
  }

  h3 + p {
    margin-top: 25px;
  }

  .o-section__img {
    overflow: hidden;
    width: 100%;
    border-radius: $border-radius;
    margin-top: -70px;

    + h3 {
      margin-top: 30px;
    }
  }

  .o-section__img img {
    width: 100%;
    display: block;
  }

  .m-hero--event + & {
    padding: 40px 0 0 0;
    margin-top: 0;
  }

  h3 + .m-tags {
    margin-top: 10px;
  }

  .m-tags li {
    display: inline-block;
    margin: 10px 5px 0 0;
  }

  .m-tags li a {
    display: block;
    background: $color__background--med;
    border-radius: 28px;
    padding: 6px 15px;
  }

  .m-share {
    margin-top: 30px;
    display: flex;
    align-items: center;
  }

  .m-author {
    display: flex;
    align-items: center;

    p {
      margin-top: 0;
      margin-left: 10px;
    }
  }

  .m-share__item {
    color: $color__placeholder;
    margin-left: 10px;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  .m-map__wrapper {
    margin-top: 80px;
  }

  .m-map {
    padding-bottom: 56%;
  }
}

.o-section.o-section--confirm {
	margin: 0;
	padding: 60px 0;

	ul {
		margin-left: 0 !important;
		margin-top: 1rem !important;
		
		@include media-breakpoint-up(lg) {
			margin-top: 0 !important;
		}
		
		li {
			font-weight: 600;
			font-size: 0.875rem;
			line-height: 1.3125rem;
			font-family: $sans-serif-font;
			margin-left: 1rem !important;
			
			@include media-breakpoint-up(lg) {
				margin-top: 0;
				margin-left: 0 !important;
			}
		}
	}

	.cart-header {
		@include media-breakpoint-up(lg) {
			margin-top: 30px;
		}
	}

	.cart-buttons {
		width: 100%;
		margin-top: 20px;
		color: $color__secondary;
		font-weight: bold;

		p {
			font-weight: 800 !important;
			font-size: 1rem !important;
			
			@include media-breakpoint-down(md) {
				margin-bottom: 1rem;
			}
		}

		a {
			margin-top: 0;
			margin-left: 0;
		}

	}
}

.o-section__content.o-section__break {
	margin: 60px 0;

	&--border {
		border-bottom: 1px solid red;
	}
}

.college-16-18 {
  .o-section--grid .o-section__header h2 span {
    color: $color__primary;
  }

  .o-section.bg-blue .o-section__header {
      h2, h4 {
          color: $color__white;
      }
  }

  .o-section__caption {
    .a-btn {
      background-color: $color__primary;
      color: $color__background;

      &:hover {
        background-color: darken($color__primary, 10%);
      }
    }
  }

  .o-section--grid .o-section__content .o-section__links .o-section__links-item {
    .a-btn {
      background-color: $color__black--20;
      color: $color__background;

      &:hover {
        background-color: darken($color__primary, 10%);
      }
    }
  }

  .o-section {
    .a-btn {
    //   background-color: $color__primary;
      color: $color__background;

      &:hover {
        background-color: $color__primary;
      }
    }
  }

  .o-section__header h4 {
    color: $color__primary;
  }

  .o-section .m-cards__header .a-text-link,
  .o-section__header .a-text-link {
    color: $color__primary;
  }

  .o-section__header h2 span {
    color: $color__primary;
  }

  .o-section--overlap-top .o-section__content::before {
    background-color: $color__primary;
  }

  .o-section--event h2 {
    color: $color__primary;
  }
}

.university-centre {
  .o-section--grid .o-section__header h2 span {
    color: $color__variant--1;
  }

  .o-section__caption {
    .a-btn {
      background-color: $color__variant--1;
      color: $color__background;

      &:hover {
        background-color: darken($color__variant--1, 10%);
      }
    }
  }

  .o-section--grid .o-section__content .o-section__links .o-section__links-item {
    .a-btn {
      background-color: $color__black--20;
      color: $color__background;

      &:hover {
        background-color: darken($color__variant--1, 10%);
      }
    }
  }

  .o-section {
    .a-btn {
      background-color: $color__variant--1;
      color: $color__background;

      &:hover {
        background-color: darken($color__variant--1, 10%);
      }
    }
  }

  .o-section__header h4 {
    color: $color__variant--1;
  }

  .o-section .m-cards__header .a-text-link,
  .o-section__header .a-text-link {
    color: $color__variant--1;
  }

  .o-section__header h2 span {
    color: $color__variant--1;
  }

  .o-section--overlap-top .o-section__content::before {
    background-color: $color__variant--1;
  }

  .o-section--event h2 {
    color: $color__variant--1;
  }
}

.international-students {
  .o-section--grid .o-section__header h2 span {
    color: $color__variant--4;
  }

  .o-section__caption {
    .a-btn {
      background-color: $color__variant--4;
      color: $color__background;

      &:hover {
        background-color: darken($color__variant--4, 10%);
      }
    }
  }

  .o-section--grid .o-section__content .o-section__links .o-section__links-item {
    .a-btn {
      background-color: $color__black--20;
      color: $color__background;

      &:hover {
        background-color: darken($color__variant--4, 10%);
      }
    }
  }

  .o-section {
    .a-btn {
      background-color: $color__variant--4 !important;
      color: $color__background !important;

      &:hover {
        background-color: darken($color__variant--4, 10%);
      }
    }
  }

  .o-section__header h4 {
    color: $color__variant--4;
  }

  .o-section .m-cards__header .a-text-link,
  .o-section__header .a-text-link {
    color: $color__variant--4;
  }

  .o-section__header h2 span {
    color: $color__variant--4;
  }

  .o-section--overlap-top .o-section__content::before {
    background-color: $color__variant--4;
  }

  .o-section--event h2 {
    color: $color__variant--4;
  }
}

.adult-19 {
  .o-section--grid .o-section__header h2 span {
    color: $color__variant--2;
  }

  .o-section__caption {
    .a-btn {
      background-color: $color__variant--2;
      color: $color__secondary;

      &:hover {
        background-color: darken($color__variant--2, 10%);
      }
    }
  }

  .o-section--grid .o-section__content .o-section__links .o-section__links-item {
    .a-btn {
      background-color: $color__black--20;
      color: $color__background;

      &:hover {
        background-color: darken($color__variant--2, 10%);
      }
    }
  }

  .o-section {
    .a-btn {
      background-color: $color__variant--2;
      color: $color__secondary;

      &:hover {
        background-color: darken($color__variant--2, 10%);
      }
    }
  }

  .o-section__header h4 {
    color: $color__variant--5;
  }

  .o-section .m-cards__header .a-text-link,
  .o-section__header .a-text-link {
    color: $color__variant--2;
  }

  .o-section__header h2 span {
    color: $color__variant--2;
  }

  .o-section--overlap-top .o-section__content::before {
    background-color: $color__variant--2;
  }

  .o-section--event h2 {
    color: $color__variant--2;
  }
}

.apprenticeships {
  .o-section--grid .o-section__header h2 span {
    color: $color__variant--3;
  }

  .o-section__caption {
    .a-btn {
      background-color: $color__variant--3;
      color: $color__secondary;

      &:hover {
        background-color: darken($color__variant--3, 10%);
      }
    }
  }

  .o-section--grid .o-section__content .o-section__links .o-section__links-item {
    .a-btn {
      background-color: $color__black--20;
      color: $color__background;

      &:hover {
        background-color: darken($color__variant--3, 10%);
      }
    }
  }

  .o-section {
    .a-btn {
      background-color: $color__variant--3;
      color: $color__secondary;

      &:hover {
        background-color: darken($color__variant--3, 10%);
      }
    }
  }

  .o-section__header h4 {
    color: $color__variant--3;
  }

  .o-section .m-cards__header .a-text-link,
  .o-section__header .a-text-link {
    color: $color__variant--3;
  }

  .o-section__header h2 span {
    color: $color__variant--3;
  }

  .o-section--overlap-top .o-section__content::before {
    background-color: $color__variant--3;
  }

  .o-section--event h2 {
    color: $color__variant--3;
  }
}

.employers {
    .o-section--grid .o-section__header h2 span {
        color: $color__tertiary;
    }

    .o-section__caption {
        .a-btn {
            background-color: $color__tertiary;
            color: $color__secondary;

            &:hover {
                background-color: darken($color__tertiary, 10%);
            }
        }
    }

    .o-section--grid .o-section__content .o-section__links .o-section__links-item {
        .a-btn {
            background-color: $color__black--20;
            color: $color__background;

            &:hover {
                background-color: darken($color__tertiary, 10%);
            }
        }
    }

    .o-section {
        .a-btn {
            background-color: $color__tertiary !important;
            color: $color__secondary !important;

            &:hover {
                background-color: darken($color__tertiary, 10%);
            }

            &.a-btn--disabled {
                background-color: $color__black--20 !important;
                color: $color__text--reversed !important;

                &:hover {
                    background-color: darken($color__black--20, 10%) !important;
                }
            }
        }
    }

    .o-section__header h4 {
        color: $color__tertiary;
    }

    .o-section .m-cards__header .a-text-link,
    .o-section__header .a-text-link {
        color: $color__tertiary;
    }

    .o-section__header h2 span {
        color: $color__tertiary;
    }

    .o-section--overlap-top .o-section__content::before {
        background-color: $color__tertiary;
    }

    .o-section--event h2 {
        color: $color__tertiary;
    }
}


.o-section .o-section__texttwocolumn {
	.body-text,
	.body-text li {
		@include f-body;
	}

	.body-text {
		:first-child {
			margin-top: 0;
		}
	}

	ul {
		li {
			margin-left: 15px;
			text-indent: -5px;
			list-style: none;
			position: relative;

			&:before {
				content: "-";
				position: relative;
				left: -10px;
			}
		}
	}

	h2 {
		color: $color__text;
	}

	h3 {
		color: $color__primary;
		font-size: 16px;
	}
}

.carousel-mini-item__img {
	height: 100%;
}

.button-stripe-bg {
	background: $color__black--1;
}

.o-textcolor--primary {
	color: $color__primary !important;
}

.o-textcolor--primary-light {
	color: $color__primary--light !important;
}

.o-textcolor--secondary {
	color: $color__secondary !important;
}

.o-textcolor--tertiary {
	color: $color__tertiary !important;
}

.o-textcolor--variant-1 {
	color: $color__variant--1 !important;
}

.o-textcolor--variant-2 {
	color: $color__variant--2 !important;
}

.o-textcolor--variant-3 {
	color: $color__variant--3 !important;
}

.o-textcolor--variant-4 {
	color: $color__variant--4 !important;
}

.o-textcolor--reversed {
	color: $color__background !important;
}
