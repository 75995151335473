.g-header {
  background: $color__primary;
  color: $color__text--reversed;
  padding: 0;
  width: 100vw;
  position: fixed;
  top: 0;
  transition: top 0.5s ease-in-out;
  box-shadow: 7.981px -15.01px 49px 0px rgba(0, 0, 0, 0.18);
  //overflow: hidden;
  z-index: 1300;
  transition: transform 0.4s, -webkit-transform 0.4s;

  @include media-breakpoint-up(lg) {
    overflow: inherit;
    padding: 12px 0 70px 0;
  }
  /*
  &::after {
    position: absolute;
    content: '';
    background: $color__background;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 5px;
  }
  */
}

/*
.scroll-down .g-header {
  transform: translate3d(0, -100%, 0);
}
*/

.menu-open {
  .g-header {
    overflow:visible;
  }
  &.scroll-down {
    .g-header {
      transform: none;
    }
  }
}

.g-header--salary {
  color: #ab9112 !important;
}
  
.scroll-up .g-header {
	transform: none;
}

.g-header .container-fluid {
  max-width: 1260px;
  align-items: center;
  justify-content: space-between;
}

.g-header::before {
  @include media-breakpoint-up(lg) {
    content: '';
    position: absolute;
    background: url(/dist/images/header-bg.svg) no-repeat;
    background-size: cover;
    width: 33.2%;
    height: 100%;
    right: 0;
    top: 0;
  }
}

.g-header .g-nav__toggle {
    width: 80px;
    height: 69px;
    color: $color__text--reversed;
    background: $color__secondary;
    position: relative;
    z-index: 10;
    border: none;
}

.g-header__logo {
  display: block;
  height: 47px;
  width: 100%;
  max-width: 29px;

  @include media-breakpoint-up(lg) {
    height: 74px;
    max-width: 55px;
    margin-right: 40px;
  }

  img {
    height: 100%;
    width: auto;
    max-width: none;
  }
}

.g-header__logo svg {
  height: 100%;
  width: 100%;
  display: block;
}

.g-header__tel {
  @extend %f-ui-lg;
  position: relative;

  &::after {
    content: "";
    left: 0;
    right: 0;
    bottom: -5px;
    height: 3px;
    background: $color__background;
    position: absolute;
    border-radius: 1px;
    transition: all 300ms ease;
    max-width: 0;
  }

  @include hover-supported() {
    &::after {
      max-width: 700px;
    }
  }
}

.g-header__nav {
  display: flex;
  margin-top: 45px;

  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }
}

.g-header__nav-item {
  @extend %f-ui;
  color: $color__primary;
  margin: 0 20px 0 0;
  text-decoration: none;
  position: relative;

  @include media-breakpoint-up(lg) {
    color: $color__text--reversed;
    margin: 0 12px;
  }

  &::after {
    content: "";
    left: 0;
    right: 0;
    bottom: -5px;
    height: 3px;
    background: $color__background;
    position: absolute;
    border-radius: 1px;
    transition: all 300ms ease;
    max-width: 0;
  }

  @include hover-supported() {
    &::after {
      max-width: 700px;
    }
  }
}

.g-header__btns {
  margin-top: 40px;
  margin-right: 10px;

  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }

  @include media-breakpoint-up(md) {
    position: relative;
  }

  .a-btn {
      margin-left: 10px;
      margin-right: 10px;
  }
}

.g-header .m-pills {
  flex-direction: column;
  align-items: flex-start;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    align-items: normal;
  }

  .a-btn {
    @include media-breakpoint-down(md) {
      border-radius: 5px;

      + .a-btn {
        margin-top: 10px;
      }
    }
  }
}

.g-header__cta {
  border-radius: 0;
  margin: 10px 0 -10px 0;
  z-index: 100;
  text-align: center;
  width: 100%;
  position: relative;
  padding: 20px 0;
  justify-content: center;
  border: none;
  box-shadow: inset 0px -2px 5px 0px rgba(0,0,0,0.2);
}



// Site Search

#search-result-container {
    position: absolute;
    top: 45px;
    right: 0;
    left: unset;
    z-index: 10;
    min-width: 350px;
    box-shadow: 0 8px 16px rgb(0 0 0 / 18%);

    .m-card--search {
        background: #f5f5f5;
        border-radius: 0 0 5px 5px;
    }

    #search-results-list {
        padding-bottom: 20px;
        padding-top: 20px;

        > div {
            color: #3d3d3b;
            padding: 10px 20px;
            font-size: 14px;
            line-height: 1.25em;

            &:hover {
                color: #e41f13;
                background: #ececec;
            }
            // First Item
            &:first-child {
                border-top-left-radius: 5px 5px 0 0;
            }
            // Last Item
            &:last-child {
                border-radius: 0 0 5px 5px;
            }

            .icon--arrow {
                margin-left: 10px;
                width: 13px;
            }
        }
    }
}