
/* SG: Global font changes on forms - may need be quite far reaching */
.m-form {
    font-size: 14px;
    font-weight: 600;

    .umbraco-forms-field {
        .text-danger {
            line-height: 1.4em;
        }

        .umbraco-forms-label {
            line-height: 1.4em;
        }
    }
}

.m-form__label {
	display: block;
	margin-bottom: 10px;
}

.m-form .m-form__label {
	display: block;
	margin-bottom: 10px;

	@include media-breakpoint-up(md) {
		margin-bottom: 0;
	}

  &.m-text-area__label {
    margin-top: 15px;
  }
}

.m-form__select {
	position: relative;

	// m-form__select-label
	&-label {
		width: 140px;
		font-weight: bold;
		text-transform: uppercase;
		color: $color__secondary;
	}

	// m-form__select--full
	&--full {
		width: 100%;

		select {
			border: 1px solid rgba(38, 38, 38, 0.3);
		}
	}

	select {
		padding-right: 75px; // come back bere...
        margin-bottom: 10px;

        @include media-breakpoint-up(md) {
            padding-right: 75px;
            margin-bottom: 0;
        }
	}

	&::after {
		content: '';
		background-image: url("data:image/svg+xml,%3Csvg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.1334 0L8 6.13329L1.86665 0L0 1.86665L8 9.86665L16 1.86665L14.1334 0Z' fill='%231a70bc'/%3E%3C/svg%3E%0A");
		background-repeat: no-repeat;
		background-position: center center;
		width: 45px;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		border-left: 1px solid rgba($color__separator, 0.3);
		pointer-events: none;

        @include media-breakpoint-up(lg) {
            width: 55px;
        }
	}
}

.m-form__note {
	@extend %f-ui-sm;
	margin-top: 10px;

	@include media-breakpoint-up(md) {
		margin-top: 0;
	}
}

.form-control {
	@extend %f-body;
	border-radius: 0;
	padding: 10px;
	height: auto;
	color: $color__text;
	border: 2px solid $color__white;

    @include media-breakpoint-up(lg) {
        padding: 20px;
    }

	+ .input-validation-error {
		margin-top: 10px;
	}
}

textarea.form-control {
	height: 230px;
	resize: none;
}

select.form-control {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}

.m-form .form-control.input-validation-error:not(:focus) {
	border: 4px solid $color__form--invalid;
}

input.form-control::placeholder, textarea.form-control::placeholder {
	opacity:0.6;
}

.m-form .form-control.input-validation-error:focus,
.m-form .form-control.input-validation-error:active {
  border: 3px solid $color__primary;
}

.form-control {
	&.input-validation-error {
		padding-right: 75px;
		background-image: url("data:image/svg+xml,%3Csvg%20aria-hidden%3D%22true%22%20width%3D%2230%22%20height%3D%2230%22%20focusable%3D%22false%22%20data-prefix%3D%22fas%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20fill%3D%22%23e73037%22%20d%3D%22M504%20256c0%20136.997-111.043%20248-248%20248S8%20392.997%208%20256C8%20119.083%20119.043%208%20256%208s248%20111.083%20248%20248zm-248%2050c-25.405%200-46%2020.595-46%2046s20.595%2046%2046%2046%2046-20.595%2046-46-20.595-46-46-46zm-43.673-165.346l7.418%20136c.347%206.364%205.609%2011.346%2011.982%2011.346h48.546c6.373%200%2011.635-4.982%2011.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884%200-12.356%205.78-11.981%2012.654z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E") !important;
		background-repeat: no-repeat;
		background-position: right 20px center;
	}
}

.m-form .form-control.input-validation-valid {
	padding-right: 75px;
	background-image: url("data:image/svg+xml,%3Csvg width='22' height='23' viewBox='0 0 22 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M21.9657 10.1286V4.46559C21.9657 2.00952 19.9562 0 17.5 0H4.46559C2.00952 0 0 2.00952 0 4.46559V17.5571C0 20.0131 2.00952 22.0226 4.46559 22.0226H17.5001C19.9562 22.0226 21.9657 20.0131 21.9657 17.5571V11.8706C21.9883 11.5833 22 11.2928 22 10.9996C22 10.7064 21.9882 10.416 21.9657 10.1286ZM4.15627 11.806L5.76905 10.1932L8.99501 13.419L16.2527 6.16115L17.8655 7.77393L8.99501 16.6445L4.15627 11.806Z' fill='%236AC259'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='22' height='22.0226' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
	background-repeat: no-repeat;
	background-position: right 20px center;
}

// Changed Select Validation
.m-form select.form-control.input-validation-valid {
	padding-right: 73px;
	background-position: right 50px center;

    @include media-breakpoint-up(lg) {
        padding-right: 114px;
        background-position: right 70px center;
    }
}

.m-form .input-validation-error {
	@extend %f-body;
	color: $color__form--invalid;
	display: block;
}

.m-form__checkbox {
	position: relative;
	margin: 0;
	display: inline-flex;
	align-items: center;
	margin-right: 30px;
	margin-top: 10px;

	p + & {
		margin-top: 30px;
	}

	&.form-check-inline + .m-form__checkbox {
		margin-right: 30px;
	}

  &.form-check-block {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
}

.m-checkbox-form-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 20px 0 0 0;

  .m-form__checkbox {
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
      width: 50%;
      margin-bottom: 25px;
    }
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  .m-form__checkbox.form-check-inline + .m-form__checkbox {
    margin-left: 0px;
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
      width: 50%;
      margin-bottom: 25px;
    }
  }
}

.m-form__checkbox input[type="checkbox"] {
	opacity: 0;
	width: 22px;
	height: 22px;
	padding: 0;
	margin: 0;
}

.m-form__checkbox label {
	display: inline-block;
	padding-left: 15px;
}

.m-form__checkbox label::before,
.m-form__checkbox label::after {
	position: absolute;
	content: "";
	display: inline-block;
}

.m-form__checkbox label::before {
	height: 22px;
	width: 22px;
	border: 1px solid $color__secondary;
	left: 0px;
	top: 0px;
	border-radius: 5px;
}

.m-form__checkbox label::after {
	height: 5px;
	width: 11px;
	border-left: 2px solid $color__text--reversed;
	border-bottom: 2px solid $color__text--reversed;
	transform: rotate(-45deg);
	left: 5px;
	top: 7px;
}

.m-form__checkbox label a {
  position: relative;
  display: inline-block;

  &:after {
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background: $color__text;
    position: absolute;
    z-index: 1;
    border-radius: 1px;
  }
}

.m-form__checkbox input[type="checkbox"] + label::after {
	content: none;
}

.m-form__checkbox input[type="checkbox"]:checked + label {
  color: $color__secondary;
  text-align: left;
  line-height: 1.5;
}

.m-form__checkbox input[type="checkbox"]:checked + label::before {
	border-color: $color__form--valid;
	background: $color__form--valid;
}

.m-form__checkbox input[type="checkbox"]:checked + label::after {
	content: "";
}

.m-form__checkbox input[type="checkbox"]:focus + label::before {
	outline: rgb(59, 153, 252) auto 5px;
}

.m-form__radio {
	position: relative;
	margin: 0;

	p + & {
		margin-top: 30px;
	}

	&.form-radio-inline + .m-form__radio {
		margin-left: 30px;
	}
}

.m-form__radio input[type="radio"] {
	opacity: 0;
	width: 22px;
	height: 22px;
	padding: 0;
	margin: 0;
	display: inline-block;
	vertical-align: middle;
}

.m-form__radio label {
	display: inline-block;
	padding-left: 13px;
	vertical-align: middle;
}

.m-form__radio label::before,
.m-form__radio label::after {
	position: absolute;
	content: "";
	display: inline-block;
}

.m-form__radio label::before {
	height: 22px;
	width: 22px;
	border: 2px solid $color__secondary;
	left: 0px;
	top: 0px;
	border-radius: 100%;
}

.m-form__radio label::after {
	height: 8px;
	width: 8px;
	background: $color__secondary;
	border-radius: 100%;
	left: 7px;
	top: 7px;
}

.m-form__radio label a {
  position: relative;
  display: inline-block;

  &:after {
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background: $color__text;
    position: absolute;
    z-index: 1;
    border-radius: 1px;
  }
}

.m-form__radio input[type="radio"] + label::after {
	content: none;
}

.m-form__radio input[type="radio"]:checked + label {
  color: $color__secondary;
}

.m-form__radio input[type="radio"]:checked + label::after {
	content: "";
}

.m-form__radio input[type="radio"]:focus + label::before {
	outline: rgb(59, 153, 252) auto 5px;
}

.m-form__date.form-inline .input-group {
	width: calc(33.33% - 16px);
	margin: 0 8px;

	.form-control {
		width: 100%;
	}
}


.custom-file {
	position: relative;
	padding-right: 65px;
	color: rgba($color__text, 0.5);
}

.custom-file input {
	opacity: 0;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	;
}

.custom-file-icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  color: $color__secondary;
  border-left: 1px solid rgba($color__separator, 0.2);
}

#thank-you-message {
  display: none;
}

#form-box-display {
  display: block;
}

.m-form__collapse {
  margin-top: 20px;

  .m-form__checkbox {
    label {
      font-size: 14px;
    }

    input[type=checkbox]:checked {
      + label {
        color: $color__secondary;
      }
    }
  }
}

.form-control {
  transition: all 300ms ease;

  &:active, &:focus {
    box-shadow: none;
  }
}

input.form-control,
select.form-control {
  border: 2px solid $color__white;

  &:active, &:focus {
    border: 2px solid $color__primary;
  }
}

.m-filter__select .form-control {
	&:active,
	&:focus {
		border-top-color: $color__white;
		border-left-color: $color__white;
		border-right-color: $color__white;
	}
}
