.m-quote {
  height: 100%;
  display: block;
  flex-wrap: wrap;
  // margin-top: 40px;
  padding-bottom: 40px;

  @include media-breakpoint-up(md) {
    display: flex;
    margin-top: -5px;
  }
}

.m-quote__bubble {
    @extend %f-h4;
    line-height: 1.65em;
    font-weight: 500;
    border-radius: 40px;
    background-color: $color__background;
    box-shadow: -14.333px 49.986px 49px 0px rgba(0, 0, 0, 0.11);
    padding: 35px 35px;
    text-align: center;
    position: relative;
    margin-bottom: 25px;
    color: $color__primary;

    &::after {
        content: '';
        border-width: 27.5px 27.5px 0 27.5px;
        border-color: $color__background transparent transparent transparent;
        border-style: solid;
        position: absolute;
        bottom: -25px;
        left: 50%;
        margin-left: -27.5px;
    }

    span.quote::before,
    span.quote::after {
        content: '“';
        font-size: 2.86em;
        display: block;
        text-align: left;
        font-weight: normal;
    }

    span.quote::after {
        content: '”';
        text-align: right;
    }
}

.m-quote--left {
  .m-quote__bubble {
    color: $color__variant--1;
  }

  .m-quote__bubble::after {
    @include media-breakpoint-up(md) {
      left: 70px;
      margin: 0;
    }
  }
  
  @include media-breakpoint-up(md) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.m-quote--center {
    @include media-breakpoint-up(md) {
      padding-left: 20px;
      padding-right: 20px;
    }
}

.m-quote--right {
  .m-quote__bubble {
    color: $color__secondary;
  }

  .m-quote__bubble::after {
    @include media-breakpoint-up(md) {
      right: 70px;
      left: auto;
      margin: 0;
    }
  }
  
  @include media-breakpoint-up(md) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.m-quote__bubble + .a-btn {
  margin: 45px auto 0 auto;
  display: block;
  align-self: flex-end;

  @include media-breakpoint-up(md) {
    margin: 25px auto 0 auto;
  }
}

.author {
    color: $color__text;
}
