.g-nav {
    width: 100%;
    background: $color__background;
    padding: 70px 0 22px 0;

    @include media-breakpoint-up(lg) {
        height: 58px;
        padding:22px 0;
    }

    .full-width {
        display: flex;
        flex-grow: 1;
    }

    &.-dropdown {
        top: 100% !important;
        height: auto;
        background: transparent;
        padding: 0;
        z-index: 50 !important;
        display: none;

        @include media-breakpoint-down(lg) {
            top: 0 !important;
            position: relative;
            margin-top: 25px;
            display: none;
        }
    }
}

.parent-link{
  flex-direction: column;

  &:hover {
    > .nav-dropdown.nav-inner-child {
      display: flex;

      @include media-breakpoint-down(md) {
        margin-top:10px;
      }

      @include media-breakpoint-up(lg) {
        position: absolute;
        left: 0;
        top: 100%;
      }
    }
  }
}

// Last Header Menu Item with Drop Down - position right
#mobileMenu .g-nav .g-nav__item:last-child {
    &.parent-link:hover {
        > .nav-dropdown.nav-inner-child {
            left: unset;
            right: 0;
        }
    }
}

.nav-dropdown {
  flex-wrap: wrap;
  flex-direction: column;
  display: flex;
  background: $color__background;
  max-width: 260px;
  min-width: 260px;
  width: 100%;
  box-shadow: 0.981px 42.99px 61px -14px rgba(0, 0, 0, 0.18);
  padding: 1rem 0;

  &.nav-inner-child {
    display:none;
  }

  @include media-breakpoint-down(lg) {
    box-shadow: none;
  }

  a {
    padding: 0.7rem 1.4rem;

    @include media-breakpoint-down(md) {
      padding: 0rem 1.4rem;
    }

    @include media-breakpoint-up(lg) {
      &:hover {
        background: $color__grey--2;
      }
    }
  }
}

.g-nav .grid-container {
  padding-left: 0;
  padding-right: 0;
  position: relative;

  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: space-between;
  }
}

.g-nav__item {
  @extend %f-label-1;
  text-decoration: none;
  color: $color__secondary;
  border: none;
  padding: 0;
  background: none;
  display: flex;
  width: 100%;
  cursor: pointer;
  position: relative;

  &:not(:first-child) {
    margin-top: 25px;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    display: inline-flex;
    margin-top: 0;
    width: auto;
  }

  svg {
    margin-left: auto;
    color: $color__primary;
    transition: all 300ms ease;

    @include media-breakpoint-up(lg) {
      margin-left: 10px;
    }
  }

  &:hover {
    svg {
      transform: translateY(3px);
    }
  }
}

.g-nav__item--highlight {
  @include media-breakpoint-up(lg) {
    color: $color__primary;
  }
}

.g-nav__mobile {
  position: fixed;
  background: $color__background;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 25px 25px;
  z-index: 1000;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  opacity: 0;
  visibility: hidden;
  transition: 200ms all ease;

  @include media-breakpoint-up(md) {
    overflow: visible;
  }

  @include media-breakpoint-up(lg) {
    position: static;
    background: none;
    width: auto;
    height: auto;
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    padding: 0;
    opacity: 1;
    visibility: visible;
    overflow: visible;
  }

  &.show {
    opacity: 1;
    visibility: visible;
  }

  .g-nav {
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: auto;
      bottom: -1px;
      left: 50%;
      right: 0;
      width: 100vw;
      margin-left: -50vw;
      z-index: 4;
    }
  }
}

.g-nav__mobile-close {
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    position: absolute;
    top: 25px;
    right: 25px;

    .icon--close {
        width: calc(8.8px*2.5);
        height: calc(12.8px*2.5);
    }
}

.icon--menu,
.icon--menu svg {
    width: 30px;
    height: 17px !important;
}

.nav--custom {
  display: flex;
  align-items: center;

  .nav--custom-phone {
    padding-left: 1em;
    .nav--custom-phone--svg {
      height: 20px;
      width: 20px;
      @include media-breakpoint-up(md) {
        height: 26px;
        width: 26px;
      }

    }
  }
}

.nav--custom-apply {
    padding-left: 10px;
}
