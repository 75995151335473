.m-user-content ul {
  @extend %f-body;
  margin-top: 20px;

  li {
    padding-left: 10px;
    position: relative;
  }

  li:before {
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 100%;
    position: absolute;
    top: 10px;
    left: 0;
    background: #666666;
  }

  li + li {
    margin-top: 15px;
  }
}

.dash-list {
  li + li {
		margin-top: .4em;
	}
	li {
    margin-left: 2.25em;
    list-style: none !important;
    position: relative;

    &::before{
      content: '\2014';
      right: calc(100% + 0.5em); 
      height: 1em;
      position: absolute;
    }
  }
}

.m-user-content h3 {
  @extend %f-h4;
  color: $color__secondary;
}

.m-user-content h4 {
    font-size: 80%;

    a {
        display: inline-block;
        position: relative;

        &:after {
            content: "";
            left: 0;
            right: 0;
            bottom: -4px;
            height: 1px;
            background: $color__text;
            position: absolute;
            z-index: 1;
            border-radius: 1px;
        }

        &:hover {
            color: $color__navy;

            &:after {
                backgound-color: $color__navy;
            }
        }
    }
}
