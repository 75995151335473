.m-gallery {
  .o-section__content & {
    margin-top: 30px;
  }
}

.m-gallery__image {
  border-radius: $border-radius;
  overflow: hidden;
  position: relative;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  .a-btn--fullscreen {
    position: absolute;
    width: 44px;
    height: 44px;
    bottom: 15px;
    right: 15px;
    opacity: 0.8;
    text-align: center;
    justify-content: center;
    align-items: center;

    @include hover-supported() {
      opacity: 1;
    }
  }

  .gallery-video--play {
    display: none;
  }
}

.m-gallery__video {
  border-radius: $border-radius;
  overflow: hidden;
  position: relative;

  iframe {
    display: block;
    width: 100%;
  }

  .a-btn--fullscreen {
    position: absolute;
    width: 44px;
    height: 44px;
    bottom: 15px;
    right: 15px;
    opacity: 0.8;
    text-align: center;
    justify-content: center;
    align-items: center;

    @include hover-supported() {
      opacity: 1;
    }
  }
}

.m-gallery__caption {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 17px 25px 13px 25px;
  background: $color__background;
  max-width: calc(100% - 90px);
  border-radius: 0 $border-radius 0 0;
  font-size: rem-calc(12);
}

.bg-blue {

    // Full gallery only (for now)
    &.gallery-full {
        .o-section__header {
            h4, h2 {
                color: $color__white;
            }
        }
    }

    .m-gallery__caption {
        background: $color__navy;
        color:#fff;
    }
}

.m-gallery__thumbs {
  margin-left: -5px;
  margin-right: -5px;
  display: flex;
  flex-flow: row wrap;
  margin-top: 30px;

  @include media-breakpoint-up(md) {
    margin-top: 0 !important;
  }
}

.m-gallery__thumbs li {
  padding: 0 5px 10px 5px;
  width: 33.33%;
  list-style: none !important;
  margin-top: 0 !important;
  margin-left: 0 !important;

  @include media-breakpoint-up(sm) {
    width: 25%;
  }
}

.m-gallery__btn {
  display: block;
  border-radius: $border-radius;
  overflow: hidden;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  position: relative;
  width: 100%;
  max-height: 64px;

  img {
    display: block;
    width: 100%;
  }

  iframe {
    display: block;
    width: 100%;
    border-radius: 5px;
  }

  .icon--play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }

  .icon--play, .icon--play svg {
    width: 26px;
    height: 42px;
  }
}

.m-gallery__btn.active::after {
  font-size: rem-calc(12);
  font-weight: 600;
  content: 'Selected';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba($color__primary, 0.8);
  color: $color__text--reversed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.college-16-18 {
  .m-gallery__btn.active::after {
    background: rgba($color__primary, 0.8);
    color: $color__text--reversed;
  }
}

.university-centre {
  .m-gallery__btn.active::after {
    background: rgba($color__variant--1, 0.8);
    color: $color__text--reversed;
  }
}

.international-students {
  .m-gallery__btn.active::after {
    background: rgba($color__variant--4, 0.8);
    color: $color__secondary;
  }
}

.adult-19 {
  .m-gallery__btn.active::after {
    background: rgba($color__variant--2, 0.8);
    color: $color__secondary;
  }
}

.apprenticeships {
  .m-gallery__btn.active::after {
    background: rgba($color__variant--3, 0.8);
    color: $color__secondary;
  }
}

.employers {
  .m-gallery__btn.active::after {
    background: rgba($color__tertiary, 0.8);
    color: $color__secondary;
  }
}

.gallery_full {
	padding: 30px 0;
	@include media-breakpoint-up(md) {
		padding: 55px 0;
	}
	&.bg-blue {
		h4 {
			color:#fff;
		}
	}
}
