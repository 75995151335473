.m-card {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        display: block;
    }
}

.m-card .a-media__img {
  + h3,
  + h4 {
    margin-top: 20px;
  }
}

.m-cards__header {
  color: $color__secondary;
  display: flex;
  align-items: center;

  h3 {
    @extend %f-h4;
  }

  h3 + .a-text-link {
    margin-left: 20px;
  }
}

.m-card h3 {
  color: $color__secondary;
  text-transform: uppercase;
}

.m-card h4 {
  @extend %f-h4;
  color: $color__secondary;

  + p {
    margin-top: 15px;
  }
}

.m-card .f-h2 {
  display: block;
  color: $color__primary;
  margin-top: 10px;
}

.m-card .a-btn {
  margin-top: 20px;
}

.m-card__meta {
  @extend %f-body;
  display: block;
  color: $color__secondary;
  margin-top: -10px;

  + p, + h2, + h3, + h4 {
    margin-top: 15px;
  }
}

.m-card__content {
  p a {
    color: $color__secondary;
    font-weight: 900;
  }
}

.m-card--stat h3,
.m-hero .m-card--stat h3 {
  @extend %f-h3;
  margin-top: 25px;
}

.m-card--stat p {
    color: $color__text;
}

.m-card--image {
  border-radius: $border-radius;
  overflow: hidden;
  position: relative;
  padding-bottom: 0;
  display: block;
  margin-top: 25px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 10px;
  }

  img {
    display: block;
    width: 100%;
  }
}

.m-card--image .m-card__caption {
  @extend %f-h4;
  display: flex;
  flex-flow: row wrap;
  color: $color__secondary;
  background: $color__caption;
  padding: 30px 25px;

  svg {
    margin-left: auto;
    margin-top: 4px;
    flex-shrink: 0;
  }
}

.m-card__img {
  position: relative;

  img {
    display: block;
    width: 100%;
  }

  // + .m-card__content {
  //   margin-top: 25px;
  // }

  .icon--play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $color__text--reversed;
  }
}

.m-card--image .m-card__img {
  + .m-card__content {
    margin-top: 25px;
  }
}

.m-card--image .m-card__content {
  h4 {
    @extend %f-h4;
    text-transform: uppercase;
    color: $color__secondary;
  }

  > svg {
    margin-top: 15px;
    color: $color__primary;
  }

  p:not(:first-child) {
    margin-top: 10px;
  }
}

.m-card.m-card--bio {
  h4 {
    @extend %f-h3;
    color: $color__primary;
  }

  .m-card__position {
    @extend %f-h4;
    color: $color__secondary;
    margin-top: 15px;
    display: block;

    + p {
      margin-top: 15px;
    }
  }
}

.m-card.m-card--bio.m-card--simple {
  margin: 25px 0;

  h4 {
    color: $color__text;
  }

  .m-card__position {
    color: $color__text;
    margin-top: 10px;
  }
}

.m-card__handle {
  display: flex;
  align-items: center;
  padding-bottom: 15px;

  img {
    margin-right: 10px;
    flex-shrink: 0;
  }
}

.m-card--overlay {
  position: relative;
  overflow: hidden;

  .o-section__content & {
    &.m-card {
      margin-top: 30px;

      @include media-breakpoint-up(lg) {
        margin-top: 40px;
      }
    }
  }

  + .m-card--overlay {
    margin-top: 25px;
  }

  .m-card__img {
    width: 100%;
    height: 100%;
    border-radius: $border-radius;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      margin-bottom: 60px;
    }

    img {
      object-fit: cover;
      display: block;
    }
  }

  .m-card__content {
    position: relative;
    background: $color__background;
    padding: 20px 65px 20px 20px;
    border-radius: 0 5px 5px 0;
    overflow: hidden;
    bottom: 0;
    left: 0;
    width: calc(100% - 20px);
    margin-top: -80px;

    @include media-breakpoint-up(md) {
      position: absolute;
      padding: 15px 55px 15px 15px;
      border-radius: 0 5px 0 0;
      margin-top: 0;
    }

    @include media-breakpoint-up(lg) {
      width: 75%;
      padding: 35px 95px 35px 35px;
    }
  }

  .m-card__content h2 {
    @extend %f-h3;
    color: $color__secondary;
    line-height: 1.4;
    text-transform: uppercase;

    + p {
      margin-top: 20px;
    }
  }

  .m-card__content h3 {
    @extend %f-label-2;
    color: $color__secondary;
    line-height: 1.4;

    + p {
      margin-top: 20px;
    }
  }

  .m-card__content .a-btn {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 45px;
    min-width: 0;
    border-radius: 0;
    margin: 0;
    justify-content: center;

    @include media-breakpoint-up(md) {
      width: 50px;
    }

    @include media-breakpoint-up(lg) {
      width: 65px;
    }

    svg {
      margin: 0;
    }
  }

  // m-card--overlay_container
  &_container {
    display: initial !important;
  }
}

.m-card--overlay .m-card__label {
  @extend %f-label-1;
  position: absolute;
  background: $color__background;
  color: $color__secondary;
  top: 40px;
  left: 45px;
  padding: 6px 12px;
  border-radius: $border-radius;
}

.m-card--basic {
  background: $color__background--med;
  padding: 45px 40px;
  border-radius: $border-radius;
  margin-top: 30px;

  .o-section__content & {
    &.m-card {
      @include media-breakpoint-up(md) {
        margin-top: 30px;
      }
    }
  }

  h3 {
    font-weight: 900;
  }

  h3 + p {
    margin-top: 15px;
  }

  em {
    font-style: normal;
    color: $color__primary;
    font-weight: 900;
  }

  p a {
    color: $color__text;
    font-weight: 600;
    position: relative;
    display: inline-block;

    &:after {
      content: "";
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background: $color__text;
      position: absolute;
      z-index: 1;
      border-radius: 1px;
    }
  }

  .m-card__content {
    p {
      @include media-breakpoint-up(md) {
        max-width: 400px;
      }
    }
  }
}

.m-card--basic.m-card--variant-3 {
  background: $color__variant--3;
  color: $color__secondary;
}

.m-card--stack {
  display: grid;
  -ms-grid-columns: 60px 1fr 60px;
    grid-template-columns: 60px 1fr 60px;
    -ms-grid-rows: 60px 1fr;
    grid-template-rows: 60px 1fr;
    align-items: flex-start;
    display: -ms-grid;
    height: calc(100% - 30px);

    @include media-breakpoint-up(lg) {
      height: calc(100% - 15px);
    }

    .m-card__content {
      height: 100%;
    }
}

.m-card--stack .m-card__img {
  // grid-area: card;
  // margin-left: 60px;
  // width: calc(100% - 60px);
  -ms-grid-column: 2;
  -ms-grid-column-span: 2;
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
grid-column: 2/4;
    grid-row: 1/3;

  @include media-breakpoint-only(md) {
    margin-left: 30px;
    // width: calc(100% - 30px);
  }

  + .m-card__content {
    // margin-top: 60px;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    -ms-grid-row: 2;
    -ms-grid-row-span: 2;
    grid-column: 1/3;
    grid-row: 2/3;

    @include media-breakpoint-only(md) {
      // margin-top: 30px;
    }
  }
}

.m-card--stack .m-card__content {
  grid-area: card;
  padding: 40px 40px;
  border-radius: $border-radius;
  background: rgba($color__caption, 0.95);
  // width: calc(100% - 60px);
  position: relative;

  @include media-breakpoint-only(md) {
    width: calc(100% - 30px);
    padding: 30px;
  }

  .a-text-link {
    color: $color__secondary;

    &::after {
      content: "";
      left: 0;
      right: 0;
      bottom: -5px;
      height: 3px;
      background: $color__secondary;
      position: absolute;
      border-radius: 1px;
      transition: all 300ms ease;
      max-width: 0;
    }
  
    @include hover-supported() {
      &::after {
        max-width: 700px;
      }
    }
  }

  .a-text-link + .a-btn {
    margin-top: 20px;
  }

  p + .a-text-link {
    margin-top: 20px;
  }
}

.m-block--facilities-text {
  padding: 0;

  h1 {
    color: $color__secondary;
    font-weight: bold;
    margin-bottom: 20px;
  }

  @include media-breakpoint-up(md) {
    padding: 0 50px 0 0;
  }
}

.m-block--facilities-header {

  h1 {
    color: $color__secondary;
    font-weight: bold;
    margin-bottom: 35px;
    font-size: 50px;
    line-height: 1.2;
  }

  p {
    color: $color__secondary;
    margin-bottom: 35px;
  }

  img {
    width: 100%;
  }
}

.m-card--event {
  display: flex;
}

.m-card--event-container {
  width: 100%;
  margin-top: 30px;

  h1 {
    color: $color__secondary;
    font-weight: bold;
    margin-bottom: 20px;
  }

  @include media-breakpoint-up(md) {
    margin-top: 0px;
  }
}

.m-card--event .m-card__content {
  grid-area: card;
  padding: 40px 40px;
  border-radius: $border-radius;
  background: $color__caption;
  width: 100%;
  position: relative;

  @include media-breakpoint-only(md) {
    width: 100%;
    padding: 30px;
  }

  .a-text-link {
    color: $color__secondary;
  }

  .a-text-link + .a-btn {
    margin-top: 20px;
  }

  p + .a-text-link {
    margin-top: 20px;
  }
}

.m-card--stack.m-card--blue .m-card__content {
    background: rgba($color__primary, 0.95);
    color: $color__text--reversed;

    h2, h3, h4, .a-text-link {
        color: $color__text--reversed;
    }

    .a-text-link:after {
        background-color: $color__text--reversed;
    }
}

.m-card--stack.m-card--light-blue .m-card__content {
    background: rgba($color__primary--light, 0.95);
    color: $color__text--reversed;

    h2, h3, h4, .a-text-link {
        color: $color__text--reversed;
    }

    .a-text-link:after {
        background-color: $color__text--reversed;
    }
}

.m-card--stack.m-card--dark .m-card__content {
    background: rgba($color__secondary, 0.95);
    color: $color__text--reversed;

    h2, h3, h4, .a-text-link {
        color: $color__text--reversed;

        &::after {
            content: "";
            left: 0;
            right: 0;
            bottom: -5px;
            height: 3px;
            background: $color__text--reversed;
            position: absolute;
            border-radius: 1px;
            transition: all 300ms ease;
            max-width: 0;
        }

        @include hover-supported() {
            &::after {
                max-width: 700px;
            }
        }
    }

    .a-text-link:after {
        background-color: $color__text--reversed;
    }
}

.m-card--stack.m-card--tertiary .m-card__content {
  background: rgba($color__tertiary, 0.95);
}

.m-card--stack.m-card--variant-1 .m-card__content {
    background: rgba($color__variant--1, 0.95);
    color: $color__text--reversed;

    h2, h3, h4, .a-text-link {
        color: $color__text--reversed;
    }

    .a-text-link:after {
        background-color: $color__text--reversed;
    }
}

.m-card--stack.m-card--variant-2 .m-card__content {
  background: rgba($color__variant--2, 0.95);
}

.m-card--stack.m-card--variant-3 .m-card__content {
  background: rgba($color__variant--3, 0.95);
}

.m-card--stack.m-card--variant-4 .m-card__content {
    background: rgba($color__variant--4, 0.95);
    color: $color__text--reversed;

    h2, h3, h4, .a-text-link {
        color: $color__text--reversed;
    }

    .a-text-link:after {
        background-color: $color__text--reversed;
    }
}

.card--flex--container {
  display: flex;
}

.card--flex-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;

  @include media-breakpoint-up(md) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.remove--mobile {
    display: none;

    @include media-breakpoint-up(md) {
        display: block;
    }
}


.card--ellipsis-item {
  border-radius: 50%;
  height: 14px;
  width: 14px;
  margin: 0 3px;
  justify-self: flex-end;
  border: none;

  &:last-child {
    margin: 0 0 0 3px;
  }
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.m-card--event.m-card--dark .m-card__content {
  background: $color__secondary;
  color: $color__text--reversed;

  h2, h3, h4, .a-text-link {
    color: $color__text--reversed;
  }
}

.m-card--info {
  border-radius: $border-radius;
  padding: 30px 30px 30px 30px;
  background: $color__background--dark;
  border-top: 8px solid $color__background--med;

  .a-text-link {
    @extend %f-h4;
    color: $color__secondary;
    margin-top: 30px;
  }

  p em {
    color: $color__secondary;
    font-style: normal;
    font-weight: 900;
  }

  .o-section__content & {
    &.m-card {
      @include media-breakpoint-up(md) {
        margin-top: 25px;
      }
    }
  }
}

.m-card__container {
  flex-grow: 1;

  > div {
    display: flex;
  }

  .m-card {
    width: 100%;
  }

  .m-card.m-card--image {
    display: flex;
    flex-direction: column;

    .m-card__caption {
      flex-grow: 1;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      & > div {
        max-width: initial;

        @include media-breakpoint-up(md) {
          max-width: 200px;
        }

        + svg {
          margin-top: 0;
        }
      }
    }
  }
}

.m-card__image--rounded {
  height: auto;
  display: flex;
  @include tools-ratio(1, 1, $debug);
  width: 50%;
  padding-top: 0;
  padding-bottom: 20px;

  @include media-breakpoint-up(md) {
    width: 100%;
    padding-bottom: 0;
  }

  @include media-breakpoint-up(lg) {
    padding: 0 10px 10px;
  }

  img {
    border-radius: 50%;
    object-fit: cover;
    width: 100%;
    min-width: 170px;
    min-height: 170px;

    @include media-breakpoint-up(sm) {
      min-width: initial;
      min-height: initial;
    }
  }
}

.col-md-4 {
  .m-card__image--rounded {
    @include media-breakpoint-up(lg) {
      padding: 0 25px 10px;
    }
  }
}

.m-card__tags {
  margin: 20px 0;
}

.m-card__tags + p a span {
  color: $color__text;
  font-weight: 600;
  position: relative;
  display: inline-block;

  &:after {
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background: $color__text;
    position: absolute;
    z-index: 1;
    border-radius: 1px;
  }
}

.m-card__tag {
  background-color: $color__white;
  display: inline-block;
  margin: 6px 6px 6px 0;
  padding: 8px 16px;
  border-radius: 8px;

  h4 {
    color: $color__primary;

    &.color--red {
      color: $color__variant--1;
    }

    &.color--teal {
      color: darken($color__variant--2, 15%);
    }

    &.color--yellow {
      color: darken($color__variant--3, 15%);
    }

    &.color--purple {
      color: $color__variant--4;
    }
  }
}

.m-card__title {
  text-transform: none;
}

.m-card__label {
  font-size: rem-calc(13);
  display: inline-block;
  padding: 5px 10px;
  font-weight: 900;
  margin-top: 15px;
  background: $color__background;
  border-radius: $border-radius;

  + p {
    margin-top: 20px;
  }

  &.m-card__label--variant-3 {
    color: darken($color__variant--3, 20);
  }
}

.m-card--image {
  svg {
    transition: all 300ms ease;
  }

  &:hover {
    svg {
      transform: translateX(3px);
    }
  }

  .a-btn > svg {
    transform: none;
  }
}

.m-card--social {
  a {
    text-decoration: none !important;
    position: static !important;

    &:after {
      content: none !important;
    }
  }
}

.m-card--apply-confirmation {
  // m-card--apply-confirmation__item
  &__item {
    padding: 40px 0;

    &:first-child {
      padding: 20px 0 40px;
    }
  }

  h2 {
    color: $color__secondary;
  }
  h4 {
    color: $color__text;
    + p {
      margin-top: 0;

      a span {
        color: $color__text;
        font-weight: 600;
        position: relative;
        display: inline-block;

        &:after {
          content: "";
          left: 0;
          right: 0;
          bottom: 0;
          height: 1px;
          background: $color__text;
          position: absolute;
          z-index: 1;
          border-radius: 1px;
        }
      }
    }
  }

  p {
    em {
      font-size: 15px;
    }
  }
}

.m-card--border {
  border-bottom: 1px solid #ccc;
  max-width: 985px;
  margin: 0 auto;
}

.college-16-18 {
  .m-card--image .m-card__content > svg {
    color: $color__primary;
  }

  .m-card--overlay .m-card__content {
    .a-btn {
      background-color: $color__primary;
      color: $color__background;

      &:hover {
        background-color: darken($color__primary, 10%);
      }
    }
  }

  .m-card .f-h2 {
    color: $color__primary;
  }
}

.university-centre {
  .m-card--image .m-card__content > svg {
    color: $color__variant--1;
  }

  .m-card--overlay .m-card__content {
    .a-btn {
      background-color: $color__variant--1;
      color: $color__background;

      &:hover {
        background-color: darken($color__variant--1, 10%);
      }
    }
  }

  .m-card .f-h2 {
    color: $color__variant--1;
  }

  .m-card--basic em {
    color: $color__variant--1;
  }
}

.international-students {
  .m-card--image .m-card__content > svg {
    color: $color__variant--4;
  }

  .m-card--overlay .m-card__content {
    .a-btn {
      background-color: $color__variant--4;
      color: $color__background;

      &:hover {
        background-color: darken($color__variant--4, 10%);
      }
    }
  }

  .m-card .f-h2 {
    color: $color__variant--4;
  }

  .m-card--basic em {
    color: $color__variant--4;
  }
}

.adult-19 {
  .m-card--image .m-card__content > svg {
    color: darken($color__variant--2, 10%);
  }

  .m-card--overlay .m-card__content {
    .a-btn {
      background-color: $color__variant--2;
      color: $color__secondary;

      &:hover {
        background-color: #7CAFB3;
        color: $color__background;
      }
    }
  }

  .m-card .f-h2 {
    color: darken($color__variant--2, 10%);
  }

  .m-card--basic em {
    color: $color__variant--5;
  }
}

.apprenticeships {
  .m-card--image .m-card__content > svg {
    color: darken($color__variant--3, 10%);
  }

  .m-card--overlay .m-card__content {
    .a-btn {
      background-color: $color__variant--3;
      color: $color__secondary;

      &:hover {
        background-color: #AE9014;
        color: $color__background;
      }
    }
  }

  .m-card .f-h2 {
    color: darken($color__variant--3, 10%);
  }

  .m-card--basic em {
    color: darken($color__variant--3, 10%);
  }
}

.employers {
  .m-card--image .m-card__content > svg {
    color: darken($color__tertiary, 10%);
  }

  .m-card--overlay .m-card__content {
    .a-btn {
      background-color: $color__tertiary;
      color: $color__secondary;

      &:hover {
        background-color: darken($color__tertiary, 10%);
      }
    }
  }

  .m-card .f-h2 {
    color: darken($color__tertiary, 10%);
  }

  .m-card--basic em {
    color: darken($color__tertiary, 10%);
  }
}
